<template>
    
    <div class="TF BottGray MP4 LH120">
        <p class="T3">系统提示：为其他用户提供诚信担保，每增加1个用户奖励20个泛约币和10个人气信用指数；取消或因其担保对象发生失信行为的，相应的泛约币和人气信用指数会被扣除。</p>
    </div>
    <div v-if="$store.state.Certified==='成功'" class="LH200">
        <MyDivider ShowStr="我的担保对象列表"></MyDivider>
        <table v-if="Loaded">
            <thead>
            <tr class="TF LH120">
                <th>序号</th>
                <th>实名</th>
                <th>
                    <div class="MTB2">泛约用户名<br>身份证号</div>
                </th>
                <th>取消</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(x,index) in MyDBs" :key="index" class="LH120 ">
                <td class="BottGray">{{index+1}}</td>
                <td>{{x.RealName}}</td>
                <td>{{x.UserName}}<br>{{x.IDNumber}}</td>
                <td>
                    <div class="TE BDCircle BoxBR WH77 XYC" @click="Cancel(x._id)">删</div>
                </td>
            </tr>
            </tbody>
        </table>
        <div v-else class="AlignC"><br><br>（空）<br></div>
        <div class="XR YC MP4 TG" @click="Show=1">
            <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">&nbsp;追加担保对象
        </div>
    </div>
    <div v-else class="TE MP4">
        <p>您尚未通过实名认证，不能为其他用户做诚信担保！</p>
    </div>
    
    <PopUpDown v-if="Show===1" Title="被担保用户信息" :ANs="2" Pos="btt" @FromPop5="SaveDB">
        <div class="MP4 XL YC TF BottGray">
            泛约昵称:
            <input type="text" class="W50" v-model="UserName"/>
        </div>
        <div class="MP4 XL YC TF BottGray">
            用户实名:
            <input type="text" class="W50" v-model="RealName"/>
        </div>
        <div class="MP4 XL YC TF BottGray">
            身份证号:
            <input type="text" class="W60" v-model="IDNumber"/>
        </div>
    </PopUpDown>

</template>

<script>
    import {myMongoDBPost,TransferZX} from '@/components/SharedVues/Shared0.js';


    import PopUpDown from "../MyPopWin/PopUpDown";

    export default {
        name: "CXDB",
        components: {PopUpDown},
        data() {
            return {
                Loaded: false,
                MyDBs: [],
                Show: -1,
                UserName: '',
                RealName: '',
                IDNumber: ''
            }
        },
        mounted() {
            this.Refresh();
        },
        methods: {
            Refresh() {
                let that = this;
                that.ZXs = [];
                that.Loaded = false;
                myMongoDBPost("GetDocs/fyuser0", {DBUserName: that.$store.state.UserName}, function (Docs) {
                    that.MyDBs = Docs;//点操作符
                    that.Loaded = true;
                })
            },
            SaveDB(Val) {
                let that = this;
                if (Val > -1) {
                    console.log(that.UserName,that.RealName,that.IDNumber);
                    if (that.UserName === '' || that.RealName === '' || that.IDNumber === '') alert('被担保用户信息不完整!');
                    else if (that.UserName === that.$store.state.UserName) alert('为本人作担保无效!');
                    else myMongoDBPost("GetDocs/fyuser0", {UserName: that.UserName}, function (Docs) {
                            if (Docs.length === 0) alert('操作无效...，被担保用户尚未注册!');
                            else if (Docs[0].Certified === '成功') alert('被担保用户已完成实名认证，不再需要担保！');
                            else if (Docs[0].RealName !== that.RealName || Docs[0].IDNumber !== that.IDNumber) alert('担保信息不符，可能是实名或身份证号输入有误!');
                            else if (Docs[0].DBUserName !== '' && Docs[0].DBUserName === that.$store.state.UserName) alert('该用户已被担保，但不是您!');
                            else TransferZX(that, that.UserName, that.$store.state.UserName, 20, function (StrZX) {

                                    if (StrZX === "泛约币转移成功!") myMongoDBPost("UpdateDoc2/bangdan0", {
                                        UPTJ: {_id: that.$store.state.UserName},
                                        UPObj: {$inc: {PopularityCredit: 10}}
                                    }, function (data) {
                                        myMongoDBPost("UpdateDoc2/fyUser0", {
                                            UPTJ: {UserName: that.UserName},
                                            UPObj: {Certified: '成功', DBUserName: that.$store.state.UserName}
                                        }, function (data) {
                                            that.Refresh();

                                        });
                                    }); else alert('要求提供担保的用户泛约币不足，担保操作失败！')
                                });


                        });
                }
                that.Show = -1;
            },
            Cancel(Id) {
                let that = this;
                myMongoDBPost("UpdateDoc2/bangdan0", {
                    UPTJ: {_id: that.$store.state.UserName},
                    UPObj: {$inc: {PopularityCredit: -10}}
                }, function (data) {
                    myMongoDBPost("UpdateDoc2/fyuser0", {UPTJ: {_id: Id}, UPObj: {Certified: '待验证', DBUserName: ''}}, function (data) {
                        that.Refresh();
                    });
                });
            }
        }
    }
</script>

<style scoped>

</style>
