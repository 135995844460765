<template>
    <div>
        <el-divider> 通知 • 公告</el-divider>
        <div v-if="aTip!==null" class="XLR YC LH120 BGWhite PA2" @click="ClearTip">
            <div class="NoWrap">
                {{aTip.Time1}}<br>{{aTip.Time2}}
            </div>
            <img src="../../../static/gif2/ArrowR.gif" class="W12"/>
            <div class="TE">{{aTip.Tip}}</div>
        </div>
        <div class="TC MLR2 BottGray">
            <div v-if="$store.state.WXId==='未关注'">
                <img :src="'UserUpload/static/CZSM/Two9kb.jpg'" class="ML2 W26" style="float: right">
                <p class="MR4">☞请通过微信公众号“泛约大厅”访问使用，长按右侧二维码并关注置顶。</p>
            </div>
            <div class="AlignM" style="line-height: 1.8rem">
                <div class="DispIB" style="margin-left: 1.6rem;"> &nbsp;☞使用共享卡要求预存&nbsp;</div>
                <div class="DispIB AN ANBlue" @click="Show=1">泛约币</div>
                ；泛约币不够用时，须通过打擂竞技、
                <div class="DispIB TG" @click="Show=2">诚信担保</div>、
                <div class="DispIB TB" @click="Show=3">摄录代理</div>
                、技能服务等平台内应用挣取，也可以直接充值。
            </div>
            <p>
                ☞实名：<img src="../../../static/img/IDed.png" width="20">=已认证&nbsp;
                <img src="../../../static/img/ID2.png" width="20">=认证失败&nbsp;
                <img src="../../../static/img/ID3.png" width="20">=待认证
            </p>
            <p>
                ☞本平台当前正在天津通过乒乓球项目试用中，如有问题会借助“泛约天津乒乓球友群”发布信息，因此建议新注册用户通过手机微信号：18622582303，加入本群。
            </p>
        </div>
    </div>
    <PopTitle v-if="Show===1" :Title="$store.state.UserName+'的泛约币'" @FromPopUp="Show=-1">
        <ZXGL></ZXGL>
    </PopTitle>
    <PopTitle v-if="Show===2" Title="诚信担保" @FromPopUp="Show=-1">
        <CXDB></CXDB>
    </PopTitle>
    <PopTitle v-if="Show===3" Title="摄录代理服务中心" @FromPopUp="Show=-1">
        <PVProxy></PVProxy>
    </PopTitle>
    <ShowJF v-if="Show===9" :UserName="$store.state.UserName" ItemCode="a004" :CurrJF="9" @FromShowJF="Show=-1"></ShowJF>
</template>

<script>
    import {myMongoDBPost, AutoAlert2} from '@/components/SharedVues/Shared0.js'

    import ZXGL from "../IndependentVues/ZXGL";
    import PopTitle from "../MyPopWin/PopTitle";
    import FYBB from "../QYs/FYBB";
    import CXDB from "./CXDB";
    import ShowJF from "../ListYY/ShowJF";
    import PopOneAN from "../MyPopWin/PopOneAN";
    import GXCard from "../M11_GXCards/GXCard";
    import SitesList from "../M4_Sites/SitesList";
    import PVProxy from "../MyPVTs/PVProxy";
    export default {
        name: "TZGG",
        components: {PVProxy, SitesList, GXCard, PopOneAN, ShowJF, CXDB, FYBB, PopTitle, ZXGL},
        data() {
            return {
                CurrU: this.$store.state.UserName,
                Show: -1,

                aTip: null,
                fymcs1: [{_id: '紫丁香', Defeats: ['张大龙', '张小龙', '张三龙', '张四龙', '张五龙']},
                    {_id: '张小龙', Defeats: ['王大凤', '王小凤', '王三凤', '王四凤', '王五凤']},
                    {_id: '张四龙', Defeats: ['李大平', '李小平', '李三平', '李四平', '李五平']},
                    {_id: '王小凤', Defeats: ['赵大明', '赵小明', '张大龙', '赵三明', '赵四明', '赵五明']},
                    {_id: '李三平', Defeats: ['钱大户', '钱小户', '钱三户', '张大龙', '钱四户', '钱五户']},
                    {_id: '王四凤', Defeats: []},
                    {_id: '王五凤', Defeats: ['钱大户', '钱小户', '钱三户', '张大龙', '钱四户', '钱五户']},
                    {_id: '张大龙', Defeats: []},
                    {_id: '钱大户', Defeats: ['张三龙', '张四龙', '王小凤']},
                    {_id: '钱三户', Defeats: ['紫丁香']},
                    {_id: '张五龙', Defeats: ['初学者１', '初学者2']},
                    {_id: '赵小明', Defeats: []},
                    {_id: '赵五明', Defeats: []},
                ],
                fymcs2: [{_id: '马冠军', Defeats: ['王晓峰', '张大龙', '庄晓峰', '牛晓峰']},
                    {_id: '王晓峰', Defeats: ['张大龙', '李晓明', '陆大明']},
                    {_id: '张大龙', Defeats: ['孙大虎', '怪球手', '孙小虎']},
                    {_id: '怪球手', Defeats: ['马冠军', '初学者', '孙小虎']},
                    {_id: '李晓峰', Defeats: ['马冠军']},
                    {_id: '后起之秀', Defeats: ['队友一', '队友二', '马冠军', '樊亚军', '许季军', '挑战者', '打擂者', '国际友人']},
                    {_id: '国际友人', Defeats: ['欧洲五号', '伊藤美胜', '非洲三号']},
                    {_id: '欧洲五号', Defeats: ['小石村']},
                    {_id: '非洲三号', Defeats: ['瓦尔特', '本地虎']},
                    {_id: '李晓明', Defeats: []},
                    {_id: '陆大明', Defeats: []},
                    {_id: '孙大虎', Defeats: []},
                    {_id: '初学者', Defeats: []},
                    {_id: '孙小虎', Defeats: []},
                    {_id: '李晓明', Defeats: []},
                    {_id: '许季军', Defeats: []},
                    {_id: '小石村', Defeats: []},
                    {_id: '本地虎', Defeats: []}
                ],
                fymcs3: [
                    {_id: '紫丁香', Defeats: ['张大龙', '张小龙', '张三龙', '张四龙', '张五龙']},
                    {_id: '张小龙', Defeats: ['王大凤', '王小凤', '王三凤', '王四凤', '王五凤']},
                    {_id: '张四龙', Defeats: ['李大平', '李小平', '李三平', '李四平', '李五平']},
                    {_id: '王小凤', Defeats: ['赵大明', '赵小明', '紫丁香', '赵三明', '赵四明', '赵五明']},
                    {_id: '李三平', Defeats: ['钱大户', '钱小户', '钱三户', '张大龙', '钱四户', '钱五户']},
                    {_id: '王四凤', Defeats: []},
                    {_id: '王五凤', Defeats: ['钱大户', '钱小户', '钱三户', '张大龙', '钱四户', '钱五户']},
                    {_id: '钱大户', Defeats: ['张三龙', '张四龙']},
                    {_id: '钱三户', Defeats: []},
                    {_id: '张大龙', Defeats: ['孙大小', '孙二小', '孙三儿小', '孙不溜儿', '孙小']},
                    {_id: '张三龙', Defeats: []},
                    {_id: '李大平', Defeats: ['马家军１', '马家军２', '马家军３', '马家军４', '马家军５', '钱五户']},
                    {_id: '赵大明', Defeats: ['刘家军１', '刘家军２', '刘家军３', '刘家军４', '刘家军５', '刘五户']},
                    {_id: '赵五明', Defeats: ['童家军１', '童家军２', '童家军３', '童家军４', '童家军５', '童五户']},
                    {_id: '马家军２', Defeats: []},
                    {_id: '刘家军４', Defeats: ['童家军１', '童家军２']},
                    {_id: '童五户', Defeats: []},
                    {_id: '刘家军１', Defeats: ['杨家将1', '杨家将2', '杨家将3', '杨家将4', '杨家将5', '杨家将6']},
                    {_id: '杨家将1', Defeats: ['李家将1', '李家将2', '李家将3', '李家将4', '李家将5', '李家将6']},
                    {_id: '杨家将2', Defeats: ['牛家将1', '牛家将2', '牛家将3', '牛家将4', '牛家将5', '牛家将6']},
                    {_id: '杨家将3', Defeats: ['周家将1', '周家将2', '周家将3', '周家将4', '周家将5', '周家将6']},
                    {_id: '杨家将4', Defeats: ['郑家将1', '郑家将2', '郑家将3', '郑家将4', '郑家将5', '郑家将6']},
                    {_id: '杨家将5', Defeats: ['谭家将1', '谭家将2', '谭家将3', '谭家将4', '谭家将5', '谭家将6']},
                    {_id: '杨家将6', Defeats: ['穆家将1', '穆家将2', '穆家将3', '穆家将4', '穆家将5', '穆家将6']},
                    {_id: '穆家将1', Defeats: ['龙家将1', '龙家将2', '龙', '龙家将4', '龙家将5', '龙家将6']},
                    {_id: '穆家将2', Defeats: ['胡家将1', '胡家将2', '胡家将3', '胡家将4', '胡家将5', '胡家将6']},
                    {_id: '穆家将3', Defeats: ['田家将1', '田家将2', '田家将3', '田家将4', '田家将5', '田家将6']},
                    {_id: '穆家将4', Defeats: ['梁家将1', '梁家将2', '梁家将3', '梁家将4', '梁家将5', '梁家将6']},
                    {_id: '穆家将5', Defeats: ['齐家将1', '齐家将2', '齐家将3', '齐家将4', '齐家将5', '齐家将6']},
                    {_id: '穆家将6', Defeats: ['向家将1', '向家将2', '向家将3', '向家将4', '向家将5', '向家将6']},
                    {_id: '李家将2', Defeats: ['隋便1']},
                    {_id: '李家将3', Defeats: ['隋便']},
                    {_id: '李家将5', Defeats: ['隋便2']},
                    {_id: '牛家将1', Defeats: ['隋便']},
                    {_id: '牛家将4', Defeats: ['隋便']},
                    {_id: '牛家将6', Defeats: ['隋便3']},
                    {_id: '牛家将2', Defeats: ['童家将1', '童家将2', '童家将3', '童家将4', '童家将5', '童家将6']},
                ],
            }
        },
        watch: {
            Show(Val) {
                if (Val === -1) document.getElementById('TitleId').innerText = '泛约活动服务平台 > 首页';
            },
        },
        mounted() {
            let that=this;
            if (this.$store.state.LandTo.indexOf('ZXGL')>=0) {
                if (this.$store.state.UserName === '游客') AutoAlert2('游客操作无效', '请先注册登录!'); else this.Show = 1;
            }
            if (this.$store.state.LandTo.indexOf('CXDB')>=0) {
                if (this.$store.state.UserName === '游客') AutoAlert2('游客操作无效', '请先注册登录!'); else this.Show = 2;
            }
            myMongoDBPost("GetDocs/fyuser0", {UserName: this.$store.state.UserName}, function (Docs) {
                if (Docs[0] && Docs[0].XYZ && Docs[0].XYZ.Tip) that.aTip = Docs[0].XYZ;
            })
        },
        methods: {
            ClearTip() {
                let that = this;
                myMongoDBPost("UpdateDoc/fyuser0", {
                    UPTJ: {UserName: this.$store.state.UserName},
                    UPObj: {XYZ: {}}
                }, function (Docs) {
                    that.aTip = null;
                    //that.Show = 4;
                })
            },
            Setfymc(Bool) {
                let fymcs = (Bool === 1 ? this.fymcs1 : this.fymcs2);
                myMongoDBPost("RemoveDoc/fymc-a004", {}, function (data) {
                    myMongoDBPost("PutDocs/fymc-a004", fymcs, function (Docs) {
                        console.log(1, Docs)
                    })
                })
            }

        }
    }
</script>

<style scoped>
</style>
