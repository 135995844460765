<template>
    <div class="MaskCss" :style="'z-index:'+MZindex"></div>
    <transition>
        <div v-if="Show7" class="PopWin" :style="'z-index:'+(Zindex+1)+';top:'+NewTop+'px'">
            <div :style="'position: fixed;left:0;top:3mm;left:3mm;z-index:'+(Zindex+2)">
                <img v-if="Zindex<100" src="../../../static/img/ButtonPic/SY2.png" class="WH99" @click="Return">
                <img v-else src="../../../static/img/ButtonPic/CB3.png" class="WH99" @click="Return">
            </div>
            <slot></slot>
        </div>
    </transition>
</template>
<script>
    export default {
        name: "PopOneAN",
        data() {
            return {
                Show7: false,
                Timer: 0,
                MZindex: 0,
                Zindex: 0,
                SeleI: -1,
                NewTop: 0
            }
        },
        computed: {
            CurrBackN() {
                return this.$store.state.BackN
            }
        },
        watch: {
            CurrBackN(Val) {
                this.Return();
            }
        },
        mounted() {
            this.NewTop = window.scrollTop || document.documentElement.scrollTop;
            this.$store.commit('ChangeCurrZindex', this.$store.state.CurrZindex + 50);
            this.Show7 = true;
            this.Zindex = this.$store.state.CurrZindex;
            this.MZindex = this.$store.state.CurrZindex - 2;
        },
        beforeUnmount() {
            document.body.classList.remove('body-locked');
            this.$store.commit('ChangeCurrZindex', this.$store.state.CurrZindex - 50);
            if (this.Timer) clearTimeout(this.Timer);
        },
        methods: {
            Return() {
                this.Show7 = false;
                let that = this;
                this.Timer = setTimeout(function () {
                    that.$emit("FromPopUp")
                }, 1000)//确保动画完成
            }
        }
    }
</script>

<style>
    .PopOneAN {
        background-color: #ffffff;
        position: absolute;
        left: 0;
        top: 0;
        min-width: 100vw;
        min-height: 100vh;
    }
</style>
