<template>
    <div class="MP4">
        <div v-for="(A,index) in Articles" :key="index">
            <div v-if="A.MFormat==='文章标题'" class="AlignC LH300 BottGray">
                &nbsp;&nbsp;<h4>{{A.Str1}}</h4>&nbsp;&nbsp;
            </div>
            <div v-if="A.MFormat==='二级标题'" class="MT4B2">
                <h5>{{A.Str1}}</h5>
            </div>
            <div v-if="A.MFormat==='缩进'">
                <p>{{A.Str1}}</p>
            </div>
        </div>
    </div>
    <div class="BGBlue T5 TW XYC H3 MT2" @click.once="$emit('FromShowArticle')">我知道了</div>
</template>

<script>
    import {myDataFileGet} from '@/components/SharedVues/Shared0.js'
    export default {
        name: "ShowArtical",
        props: ['FindWhat'],
        data() {
            return {Articles: []}
        },
        mounted(){
            let that=this;
            myDataFileGet("DataDictionary/?FindWhat="+this.FindWhat, function (Docs) {
                that.Articles=Docs;
            })
        },

    }
</script>

<style scoped>

</style>