<template>
  <div>
    <MyDivider ShowStr="全部泛约帮办事项列表"></MyDivider>
    <div v-if="Loaded">
      <div v-if="AllBBs.length===0" class="AlignC MP4"><br><br><br>(空)</div>
      <br>
      <div v-for="(BB,index) in AllBBs" :key="index" class="XLR YC MLR4 BottGray" @click="SeleI=index" style="height:3rem">
        <div class="T4 TB ML2">{{index+1}}.{{BB.Title}}</div>
        <el-icon><ArrowRight /></el-icon>
      </div>
    </div>
    <OpenOneQY v-if="SeleI>-1" CallFrom="BB" :Msg="AllBBs[SeleI]" @OpenOneQY="OpenBBs"></OpenOneQY>
  </div>
</template>

<script>
  import {myMongoDBPost,Compare} from '@/components/SharedVues/Shared0.js'



  import OpenOneQY from "./OpenOneQY";

  export default {
    name: "FYBB_AllBBs",
    components: {OpenOneQY},
    data() {
      return {
        AllBBs: [],
        SeleI: -1,
        Loaded: false
      }
    },
    mounted() {
      this.OpenBBs();
    },
    methods: {
      OpenBBs() {
        let that = this;
        that.SeleI = -1;
        that.Loaded = false;
        myMongoDBPost("GetDocs/QYs", {Channel: '泛约帮办', EndBool: 1}, function (Docs) {
          that.AllBBs = Docs.sort(Compare('Time')).reverse();//点操作符;
          that.Loaded = true;
        })
      }
    }
  }
</script>

<style scoped>

</style>
