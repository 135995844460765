<template>
    <div class="MP4">
        <p>游客用户您好!</p>
        <div class="MT2"></div>
        <p>欢迎使用本打擂器小程序。烦请注册登录后再回来使用，您将获得更好的体验！</p>
    </div>
</template>

<script>
    export default {
        name: "DLQSetup0"
    }
</script>