<template>
    <MyDivider ShowStr="我的指数情况"></MyDivider>
    <table  style="background-color:#fff;">
      <thead>
      <tr class="TF">
        <th>人气信用</th>
        <th>综合才艺</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{Tofixed(MyPopularityCredit,2)}}</td>
        <td>{{Tofixed(MyQN,2)}}</td>
      </tr>
      </tbody>
    </table>
</template>

<script>
  import {myMongoDBPost, Tofixed} from '@/components/SharedVues/Shared0.js';


  export default {
    name: "MyIndexs",
    data() {
      return {
        MyPopularityCredit: 0,
        MyQN: 0
      }
    },
    mounted() {
      let that = this;
      myMongoDBPost("GetDocs/bangdan0", {_id: this.$store.state.UserName}, function (Docs) {
        if (Docs.length > 0) {
          that.MyPopularityCredit = Docs[0].PopularityCredit;
          that.MyQN = Docs[0].QN;
        }
      })
    },
    methods:{
      Tofixed(x, y) {
        return Tofixed(x, y)
      }
    }
  }
</script>

<style scoped>

</style>
