<template>
    <img :src="CurrPic" :class="Class"/>
</template>

<script>
    import {myVodpost} from '@/components/SharedVues/Shared0.js';

    export default {
        name: "ShowP",
        props: ['Pic','Class'],
        data() {
            return {
                CurrPic: ''
            }
        },
        mounted() {
            let that = this;
            myVodpost("getMediaInfos", {FileId: this.Pic}, function (aObj) {
                that.CurrPic = aObj.MediaUrl;
            })
        },
    }
</script>

<style scoped>

</style>