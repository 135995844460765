<template>
    <div class="WH100vw30vh XYC">
        <div v-for="number in 50" :key="number">研发中...{{number}}</div>
    </div>
</template>

<script>
    export default {
        name: "PVProxy"
    }
</script>

<style scoped>

</style>