<template>
    <div style="max-width: 100vw;overflow: hidden">
        <div class="XLR YC TB MP4 BottO">&nbsp;
            <div class="XLR YC ">
                <img class="WH77" src="../../../UserUpload/static/Img/Drum2.gif"/>
                <img class="WH99" src="../../../UserUpload/static/Img/Drum.gif"/>
                <img class="WH77" src="../../../UserUpload/static/Img/Drum2.gif"/>
            </div>
            <el-icon class="WHaa" @click="Show=1"><Setting/></el-icon>
            <div class="DLQXY WHaa TB T3 AlignC" @click="ShowArticleFind='DLQXY'"></div>
        </div>
        <div v-if="SiteZGs.length===0" class="MTB8 AlignC">
            您所在城市,{{ItemStr}}项目尚未启用打擂器!
        </div>
        <div v-else class="BDLightGray">
            <div class="XLR">
                <div class="Cell W16 H14vw">
                    <img src="../../../UserUpload/static/Img/LeftTop2.png" style="width: 16vw;height:14vw "/>
                </div>
                <div class="W84">
                    <div class="XLR">
                        <div v-for="(M,index) in MainTHs" :key="index">
                            <div v-if="index===2" class="Cell W12 H8vw TW T4 BGBlue">{{M.Week}}</div>
                            <div v-else class="Cell W12 H8vw T6">{{M.Week}}</div>
                        </div>
                    </div>
                    <div class="XLR">
                        <div v-for="(M,index) in MainTHs" :key="index">
                            <div v-if="index===2" class="Cell W12 H6vw TW T4 BGBlue ">今天</div>
                            <div v-else class="Cell W12 H6vw T3"> {{M.Date}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!--以上是表头，以下是表体...打擂场地排序依据:场地喜约人数aIndex-->
            <div v-for="(Site,index) in SiteZGs" :key="index">
                <div class="XLR">
                    <div class="W16 H30vw T3 TW XYC BGBlue BottWhite">
                        <div class="XLR YC TW T3 MT2" @click="ToSite(Site.SiteId)">
                            <span class="TE Opa">❤&nbsp;{{Site.aIndex}}</span>
                            <div></div>&nbsp;&nbsp;
                            <el-icon><LocationInformation/></el-icon>
                        </div>
                        <div class="AlignC MTB2" @click="Show=2,ZGSitesIndex=index">
                            <div v-if="Site.SiteShortName.length>3" class="NoWrap T3">{{Site.SiteShortName}}</div>
                            <div v-else class="TW NoWrap T4">{{Site.SiteShortName}}</div>
                            <div class="XLR YC">
                                <el-icon><Avatar/></el-icon>
                                <div>{{Site.Judges.length}}</div>
                                <div class="BGRed TW T3 BDCircle">&nbsp;{{Site.Sn}}&nbsp;</div>
                            </div>
                        </div>
                        <div class="XLR YC T3 TY" @click="ToCard=true">
                            <div v-if="Site.MySite" class="TE Opa">♥</div>
                            <div v-else class="TY">☞</div>
                            &nbsp;&nbsp;&nbsp;<el-icon><Postcard/></el-icon>
                        </div>
                    </div>
                    <div class="W84">
                        <div class="XLR YC" v-for="(Row,index1) in Site.Rows" :key="index1">
                            <div v-for="(Cell,index2) in Row" :key="index2">
                                <div :class="'W12 H10vw Cell CellBG'+Cell.DocBool" @click="OpenDLCell(Cell,index,index1,index2)">
                                    <div v-if="Cell.DocBool===6">
                                        <div class="T3 TW" style="position: relative;left:15px;top:-10px">
                                            <div v-if="Cell.DLStyle==='名次争夺'">≡</div>
                                            <div v-if="Cell.DLStyle==='擂台争霸'">♟</div>
                                            {{Cell.ZXn}}
                                        </div>
                                        <div class="BGWhite TB T3" style="position: relative;left: -24px;top:12px;opacity: 70%;">&nbsp;{{Cell.L2s.length}}&nbsp;</div>
                                    </div>
                                    <div v-if="Cell.DocBool===5" class="TC">
                                        <div class="T3 NoWrap">
                                            <span v-if="Cell.DLStyle==='单打挑战'">单</span>
                                            <span v-if="Cell.DLStyle==='双打挑战'">双</span>
                                            <span v-if="Cell.DLStyle==='团体挑战'">团</span>
                                            <span v-if="Cell.DLStyle==='团体对抗'">團</span>&nbsp;{{Cell.ZXn}}
                                        </div>
                                        <div class="XLR YC W12" style="position: relative;top:2px;">
                                            <div class="W5 AlignC BGWhite TG T3 Opa">
                                                <div v-if="Cell.DLStyle==='单打挑战' || Cell.DLStyle==='团体挑战' || Cell.DLStyle==='团体对抗'">
                                                    {{Cell.L1s.length}}
                                                </div>
                                                <div v-if="Cell.DLStyle==='双打挑战'">{{Cell.L1s.length*2}}</div>
                                            </div>
                                            <div class="W5 AlignC BGWhite TG T3 Opa">
                                                <div v-if="Cell.DLStyle==='单打挑战'">{{Cell.L2s.length-Cell.L1s.length}}</div>
                                                <div v-if="Cell.DLStyle==='双打挑战'">{{Cell.L2s.length-Cell.L1s.length*2}}</div>
                                                <div v-if="Cell.DLStyle==='团体挑战' || Cell.DLStyle==='团体对抗'">{{Cell.L2s.length}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="index2>2 && Cell.DocBool<3" style="opacity:0.1;">____</div>
                                    <div v-if="index2<3 && Cell.DocBool!==7" class="W12 H10vw BGBlue" style="opacity: 0.2;"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="AlignC MT2">
                <img src="../../../UserUpload/static/Img/Cutline.png" style="width:92vw;height: 140px;"/>
            </div>
        </div>
        <br> <br><br> <br><br> <br><br> <br>
    </div>
    <PopOneAN v-if="Show===1" @FromPopUp="Refresh()">
        <DLQSetup0 v-if="Role === '游客'" @FromSetup0="Refresh()"></DLQSetup0>
        <DLQSetup1 :ItemStr="ItemStr" v-if="Role === '活动管理员'" @FromSetup1="Refresh()"></DLQSetup1>
        <DLQSetup2 v-if="Role === '泛约裁判员'" :AdministratorId0="AdministratorId" :AdminZGs0="AdminZGs" @FromSetup2="Refresh()"></DLQSetup2>
        <DLQSetup3 v-if="Role === '普通用户'" @FromSetup3="Refresh()"></DLQSetup3>
    </PopOneAN>
    <PopOneAN v-if="Show===2" @FromPopUp="Refresh()">
        <PreEnroll :ItemStr="ItemStr" :AOBJ="{Role: Role,AdministratorId: AdministratorId,AdminZGs: AdminZGs,ZGSitesIndex:ZGSitesIndex}"></PreEnroll>
    </PopOneAN>
    <PopTitle v-if="Show===3" :Title="Title" @FromPopUp="Refresh()">
        <DLCell :Title="Title" :Role0="Role" :Cell0="Cell" :ColIndex0="ColIndex" :CurrCellJudge0="CurrCellJudge" :ItemStr="ItemStr" @FromDLCell="Refresh()"></DLCell>
    </PopTitle>
    <PopBlank v-if="ShowArticleFind!==''">
        <ShowArtical :FindWhat="ShowArticleFind" @FromShowArticle="ShowArticleFind=''"></ShowArtical>
    </PopBlank>
    
    <PopTitle v-if="JSON.stringify(CurrSiteDoc) !== '{}'" :Title="CurrSiteDoc.SiteName" @FromPopUp="CurrSiteDoc={}">
        <SiteLook :Msg="CurrSiteDoc"></SiteLook>
    </PopTitle>
    <PopMenu v-if="ToCard" :Menus="Menus1" :BeginN="0" @FromPopMenu="PopMenuVal">
        <GXCard v-if="SelectedN===0" :ItemStr="ItemStr"></GXCard>
        <GXCard1 v-if="SelectedN===1" :ItemStr="ItemStr"></GXCard1>
        <GXCard2 v-if="SelectedN===2" :ItemStr="ItemStr"></GXCard2>
    </PopMenu>
</template>

<script>
    import {myMongoDBPost, CloneObject} from '@/components/SharedVues/Shared0.js'
    import ShowArtical from "../MyPVTs/ShowArtical";
    import PopBott from "../MyPopWin/PopBott";
    import PopTitle from "../MyPopWin/PopTitle";
    import DLQSetup0 from "./DLQSetup0";
    import DLQSetup1 from "./DLQSetup1";
    import DLQSetup2 from "./DLQSetup2";
    import DLQSetup3 from "./DLQSetup3";
    import PreEnroll from "./PreEnroll";
    import DLCell from "./DLCell";
    import PopOneAN from "../MyPopWin/PopOneAN";
    import PopBlank from "../MyPopWin/PopBlank";
    import SiteLook from "../M4_Sites/SiteLook";
    import PopMenu from "../MyPopWin/PopMenu";
    import GXCard from "../M11_GXCards/GXCard";
    import GXCard1 from "../M11_GXCards/GXCard1";
    import GXCard2 from "../M11_GXCards/GXCard2";
    export default {
        name: "DLQ",
        components: {
            GXCard2,
            GXCard1,
            GXCard,
            PopMenu,
            SiteLook, PopBlank, PopOneAN, DLCell, PreEnroll, DLQSetup3, DLQSetup2, DLQSetup1, DLQSetup0, PopTitle, PopBott, ShowArtical
        },
        props:['ItemStr'],
        data() {
            return {
                Loaded: false,
                Role: '普通用户',
                Cell: null,
                ColIndex: 0,
                CurrCellJudge: '',
                MainTHs: [],
                AdminZGs: [],
                SiteZGs: [],
                ZGSitesIndex: 0,
                AdministratorId: '',
                ShowArticleFind: '',
                Show: -1,

                Title: '',
                CurrSiteDoc: {},

                ToCard: false,
                Menus1: ["人次卡", "时限卡", "VIP卡"],
                SelectedN: -1
            }
        },
        mounted() {
            this.Init0();
        },
        methods: {
            Init0() {
                this.Role = '普通用户';
                this.SiteZGs = [];//这一行初始化很重要
                if (this.$store.state.UserName === '游客') this.Role = '游客';
                else if (this.$store.state.UserBools.indexOf("活动管理员") >= 0) this.Role = '活动管理员';
                else if (this.$store.state.UserBools.indexOf("泛约裁判员") >= 0) this.Role = '泛约裁判员';

                let Today = (new Date()).getTime(), MainTHs = [], Weeks = ['日', '一', '二', '三', '四', '五', '六'];
                for (let i = -2; i < 5; i++) {
                    let Stamp = Today + i * 24 * 3600 * 1000;
                    let aDate = new Date(Stamp);
                    MainTHs.push({Date: (aDate.getMonth() + 1) + "." + aDate.getDate(), Week: Weeks[(new Date(aDate)).getDay()]});
                }
                this.MainTHs = MainTHs;
                this.Refresh();
            },

            //---------------以下代码启动后随即执行----------------------------
            Refresh() {
                this.Show = -1;
                let that = this, asd = {N: "中国", P: this.$store.state.UserP, C: this.$store.state.UserC, DC: this.$store.state.UserDC};
                let TJ = {P: asd.P, C: asd.C, ItemStr: this.ItemStr, DocBool: 99};//一个城市只能有1个打擂器管理员
                let Y = (new Date()).getFullYear();
                console.log(TJ)
                myMongoDBPost('GetDocs/DLQs', TJ, function (Docs) {
                    console.log(Docs)
                    if (Docs && Docs[0] && Docs[0].ZGs.length > 0) {
                        that.AdministratorId = Docs[0]._id;
                        that.AdminZGs = CloneObject(Docs[0].ZGs);
                        that.SiteZGs = Docs[0].ZGs;
                        let CellInitObj0 = {
                            ItemStr: that.ItemStr,//项目:扩充接口
                            Y: Y, M: '??', D: '??', W: '??', TW: "??",//时间,此处填充
                            P: asd.P, C: asd.C, DC: asd.DC, SiteId: '??',//地点,此处填充
                            Judge: '??', //当前擂的泛约裁判员,开挂时填充
                            L1s: [],//擂主信息,盲盒争霸时为空. 报名时填充
                            L2s: [],//攻擂者信息,报名信息. 报名时填充
                            ZGs: [],//战果信息,活动完毕后填充
                            ZG: '', Annotate: '',
                            ZXn:null, DLStyle: '??????', RuleCode: '', DocBool: -1,//开挂时重新填充
                        };
                        for (let i = 0; i < that.SiteZGs.length; i++) {
                            let Rows = [[], [], []], Noons = ['上', '下', '晚'];
                            for (let R = 0; R < 3; R++)
                                for (let j = 0; j < 7; j++) {
                                    let CellInitObj = CloneObject(CellInitObj0);
                                    CellInitObj.SiteId = that.SiteZGs[i].SiteId;
                                    CellInitObj.M = parseInt(that.MainTHs[j].Date.split(".")[0]);
                                    CellInitObj.D = parseInt(that.MainTHs[j].Date.split(".")[1]);
                                    CellInitObj.W = that.MainTHs[j].Week;
                                    CellInitObj.P = asd.P;
                                    CellInitObj.C = asd.C;
                                    CellInitObj.DC = '??';//Cell内不设区县

                                    CellInitObj.TW = Noons[R];
                                    CellInitObj.DocBool = R;
                                    Rows[R].push(CellInitObj);
                                }
                            that.SiteZGs[i].Rows = Rows;
                        }
                        that.FillDataFromServer();
                        that.FillMySite();
                    }
                })

            },
            FillDataFromServer() {
                let that = this, asd = {N: "中国", P: this.$store.state.UserP, C: this.$store.state.UserC, DC: this.$store.state.UserDC};
                let TJ = {ItemStr: this.ItemStr, P: asd.P, C: asd.C, DLStyle: {$ne: ''}};//一个城市只能有1个打擂器管理员
                myMongoDBPost('GetDocs/DLQs', TJ, function (Docs) {
                    if (Docs.length > 0) {//let TJ = { SiteId: x.SiteId, Y: x.Y, M: x.M, D: x.D, TW: x.TW }
                        for (let Si = 0; Si < Docs.length; Si++) {
                            let Obj1 = Docs[Si];
                            let TJ1 = {SiteId: Obj1.SiteId, Y: Obj1.Y, M: Obj1.M, D: Obj1.D, TW: Obj1.TW};
                            for (let i = 0; i < that.SiteZGs.length; i++)
                                for (let R = 0; R < 3; R++)
                                    for (let j = 0; j < 7; j++) {
                                        let Obj2 = that.SiteZGs[i].Rows[R][j];
                                        let TJ2 = {SiteId: Obj2.SiteId, Y: Obj2.Y, M: Obj2.M, D: Obj2.D, TW: Obj2.TW};
                                        if (JSON.stringify(TJ1) === JSON.stringify(TJ2)) that.SiteZGs[i].Rows[R][j] = Obj1;
                                    }
                        }
                    }
                })
            },
            FillMySite() {//填充我的喜好场地标志//并刷新每1个场地的aIndex
                let that = this, TJ = {UserName: this.$store.state.UserName, ItemStr: this.ItemStr};
                myMongoDBPost('GetDocs/DLQs', TJ, function (Docs) {
                    if (Docs.length > 0) {
                        for (let i = 0; i < Docs[0].MySites.length; i++)
                            for (let j = 0; j < that.SiteZGs.length; j++)
                                if (Docs[0].MySites[i].SiteId === that.SiteZGs[j].SiteId) that.SiteZGs[j].MySite = true;
                    }
                })
            },
            //====================以下代码点击时执行=========================
            ToSite(SiteId) {
                let that = this;
                myMongoDBPost('GetDocs/fysites', {_id: SiteId}, function (Docs) {
                    if (Docs && Docs.length > 0) that.CurrSiteDoc = Docs[0];
                })
            },
            PopMenuVal(Val) {
                if (Val === -1) this.ToCard = false; else this.SelectedN = Val;
            },
            OpenDLCell(Cell, index, index1, index2) {
                this.Title = this.SiteZGs[index].SiteShortName + ">" + Cell.Y + "." + Cell.M + "." + Cell.D + "星期" + Cell.W + ">" + (Cell.TW === '晚' ? '晚上' : Cell.TW + '午');
                console.log(Cell)
                if ((this.Role !== '泛约裁判员' || index2 < 3) && Cell.DLStyle === '??????') alert('您点了个空!');
                else if (this.$store.state.UserName === '游客') {
                    if (index2 < 3) alert('已结束的打擂活动信息, 游客用户点不开!');
                    else alert('报名中的打擂活动信息, 游客用户点不开!');
                } else {
                    let CurrCellJudge = false;
                    for (let i = 0; i < this.SiteZGs[index].Judges.length; i++)
                        if (this.$store.state.UserName === this.SiteZGs[index].Judges[i].Judge) CurrCellJudge = true;
                    this.Cell = Cell;
                    this.ColIndex = index2;
                    this.CurrCellJudge = CurrCellJudge;
                    this.Show = 3;
                }
            },
        }
    }
</script>

<style scoped>
    .CellBG0 {
        background: 0 0/100% 100% url('http://www.fy135.vip/UserUpload/static/Img/DLQCell1_BG.png') no-repeat;
        }
    
    .CellBG1 {
        background: 0 0/100% 100% url('http://www.fy135.vip/UserUpload/static/Img/DLQCell2_BG.png') no-repeat;
        }
    
    .CellBG2 {
        background: 0 0/100% 100% url('http://www.fy135.vip/UserUpload/static/Img/DLQCell3_BG.png') no-repeat;
        }
    
    .CellBG5 {
        background: 0 0/100% 100% url('http://www.fy135.vip/UserUpload/static/Img/DL1.png') no-repeat;
        }
    
    .CellBG6 {
        background: 0 0/100% 100% url('http://www.fy135.vip/UserUpload/static/Img/DL2.jpg') no-repeat;
        }
    
    .CellBG7 {
        background: 0 0/100% 100% url('http://www.fy135.vip/UserUpload/static/Img/Trophy.png') no-repeat;
        }
    
    .DLQXY {
        background: 0 0/100% 100% url('http://www.fy135.vip/UserUpload/static/Img/DLQXY.png') no-repeat;
        }

</style>