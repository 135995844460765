<template><br><br><br><br><br>
    &nbsp;&nbsp;&nbsp;&nbsp;临时客服电话：18622582303
</template>

<script>
    export default {
        name: "CustomerService"
    }
</script>

<style scoped>

</style>