<template>
    <div>
        <PopTitle :Title="Act1Doc.Title" @FromPopUp="Quit()">
            <div class="XR TF PA3">计分：{{Act1Doc.UserName}}</div>
            <table class="MT2">
                <thead>
                <tr class="TC LH120">
                    <th v-for="(R0,index1) in Rows[0]" :key="index1" style="padding: 2mm 0">
                        <div :class="index1===0 ? 'W12 TF':''">{{R0}}</div>
                    </th>
                </tr>
                </thead>
                <tbody v-for="(Cols,index1) in Rows" :key="index1">
                <tr v-if="index1>0">
                    <td v-for="(ZD,index2) in Cols" :key="index2" class="LH120">
                        <div v-if="index2===0" class="TC">{{ZD}}</div>
                        <div v-else>
                            <div v-if="Act1Doc.SubActType.indexOf('单循环')>=0 && index1!==index2" style="padding: 2mm 0"
                                 @click="I1=index1,I2=index2">
                                <div v-if="ZD.length>2"
                                     :class="parseInt(ZD.split(':')[0])>parseInt(ZD.split(':')[1]) ? 'TE':'TG'">
                                    <div v-if="index1>index2" class="PA2 BGLightGreen">{{ZD}}</div>
                                    <div v-if="index1<index2" class="PA2 BGY">{{ZD}}</div>
                                </div>
                                <div v-else class="TF">{{ZD=== '无' ? '―' : ZD}}</div>
                            </div>
                            <div v-if="Act1Doc.SubActType.indexOf('双循环')>=0 && index1!==index2"
                                 @click="I1=index1,I2=index2"
                                 class="TG" style="padding: 2mm 0">{{ZD}}
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <p class="TF T3">备注：胜一场得2分，输一场得1分，未出场得0分;名次相同时计小分。</p>
            <br>
            <MyDivider ShowStr="积分名次排行榜"></MyDivider>
            <table class="MT2">
                <thead>
                <tr>
                    <th>名次</th>
                    <th>参赛者</th>
                    <th>胜</th>
                    <th>负</th>
                    <th>小分</th>
                    <th>积分</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(PHB,index) in PHBs" :key="index">
                    <td>{{index+1}}</td>
                    <td>{{PHB.Name}}</td>
                    <td>{{PHB.Vn}}</td>
                    <td>{{PHB.Fn}}</td>
                    <td>{{PHB.XF}}</td>
                    <td>{{PHB.RJF}}</td>
                </tr>
                </tbody>
            </table>
            <br>
            <MySele2 v-if="I2!==-1" :Title="Rows[I1][0]+' ： '+Rows[0][I2]" :Objs="BFs"
                     @MySele2="SetZD($event,I1,I2)"></MySele2>
            <Act1PVABs :Act1Doc="Act1Doc" CallFrom="JFQ1"></Act1PVABs>
        </PopTitle>
    </div>
</template>

<script>
    import {myMongoDBPost, AutoAlert, CloneObject, Compare} from '@/components/SharedVues/Shared0.js'

    import MySele2 from "../SharedVues/MySele2";

    import Act1PVABs from "./Act1PVABs";
    import PopTitle from "../MyPopWin/PopTitle";

    export default {
        name: "Act1JFQ1",
        components: {
            PopTitle,
            Act1PVABs,

            MySele2,

        },
        props: ['ActDoc', 'Act1Doc'],
        data() {
            return {
                CurrJFMethod: '泛约',
                Rows: this.Act1Doc.TableCols,//注意：这是一个二维叔组　
                I1: -1,
                I2: -1,
                BFs: [
                    {Type: '3局2胜制', Opts: ['0:2', '1:2', '2:0', '2:1']},
                    {Type: '5局3胜制', Opts: ['0:3', '1:3', '2:3', '3:2', '3:1', '3:0']},
                    {Type: '7局4胜制', Opts: ['0:4', '1:4', '2:4', '3:4', '4:0', '4:1', '4:2', '4:3']}],
                PHBs: []//积分名次排行表
            }
        },
        mounted() {
            console.log(this.Rows);
            this.RefreshPHBs();
            let that = this;
            if (this.ActDoc.JFPool !== '泛约')
                myMongoDBPost("GetDocs/fysites", {SiteShortName: that.ActDoc.JFPool}, function (Docs) {
                    if (Docs.length > 0) that.CurrJFMethod = Docs[0].JFMethod
                });
        },
        methods: {
            Quit() {
                let that = this, Victor = '―', Second = '―', Finded = false;//Finded=查找循环是否结束标志
                if (this.PHBs.length > 1) {

                    for (let M = 1; M < this.Rows.length; M++)  //不计第0行,检测比赛所有场次是否已完成
                        for (let i = 1; i < this.Rows[M].length; i++)
                            if (this.Rows[M][i] === '―') Finded = true;

                    if (Finded) //?表示当前为胜方
                        Victor = '(?)', Second = '(?)';
                    else
                        Victor = this.PHBs[0].Name, Second = this.PHBs[1].Name;

                    myMongoDBPost("UpdateDoc/act1", {
                        UPTJ: {_id: this.Act1Doc._id},
                        UPObj: {Victor: Victor, Second: Second}
                    }, function (data2) {
                        that.$emit('FromJFQ1');
                    });
                } else that.$emit('FromJFQ1');
            },
            SetZD(Val, index1, index2) {
                let Val2 = Val === '无' ? '―' : Val;
                if (this.$store.state.UserName === this.Act1Doc.UserName || this.$store.state.UserName === this.$store.state.HTM0 || this.$store.state.UserName === '素野凝香') {
                    this.Rows[index1].splice(index2, 1, Val2);//1.先把当前数据写入
                    if (this.Act1Doc.SubActType.indexOf('单循环') >= 0) {
                        if (Val2.length > 2)
                            this.Rows[index2].splice(index1, 1, Val2.split(":")[1] + ":" + Val2.split(":")[0]);
                        else
                            this.Rows[index2].splice(index1, 1, '―');
                    }


                    let aOBJ = {
                        Act1DocId: this.Act1Doc._id,
                        Winner: this.Rows[index1][0],
                        Loser: this.Rows[0][index2],
                        BF: Val2
                    };

                    aOBJ.ItemStr = this.ActDoc.ItemStr;
                    aOBJ.JFPool = this.ActDoc.JFPool;
                    if (aOBJ.ItemStr.charAt(0).toLowerCase() === 'a') {
                        console.log("aOBJ.Winner,aOBJ.Loser,aOBJ.BF=", aOBJ.Winner, aOBJ.Loser, aOBJ.BF);
                        if (this.CurrJFMethod === '泛约') myMongoDBPost("Act1FillToFymc", aOBJ, function (asd) {
                        });
                        if (this.CurrJFMethod === 'ChinaTT') myMongoDBPost("ChinaTTToByset0", aOBJ, function (asd) {
                        })
                    }


                    myMongoDBPost("UpdateDoc/act1", {
                        UPTJ: {_id: this.Act1Doc._id},
                        UPObj: {TableCols: this.Rows}
                    }, function (data2) {
                    });
                    this.RefreshPHBs();
                } else AutoAlert('无效操作', '只有计分员才能录入比分!');
                this.I2 = -1;
            },//数组元素更新
            RefreshPHBs() {
                let Loop1 = this.Act1Doc.SubActType.indexOf('单循环') >= 0,
                    Loop2 = this.Act1Doc.SubActType.indexOf('双循环') >= 0;
                this.PHBs = [];//以下开始填充PHBs
                for (let M = 1; M < this.Rows.length; M++) this.PHBs.push({
                    Name: this.Rows[M][0],
                    Vn: 0,
                    Fn: 0,
                    XF: 0,
                    RJF: 0,
                    MC: 0
                })
                for (let M = 1; M < this.Rows.length; M++)  //不计第0行
                    for (let i = 1; i < this.Rows[M].length; i++) { //2.计算并写入当前行积分,不计第0列
                        let ThisCell = this.Rows[M][i];
                        if (ThisCell.indexOf(":") >= 0 && (Loop2 || Loop1 && M < i)) {//国际竞赛规程中规定：胜一场得2分，输一场得1分，未出场比赛或未完成比赛的场次为0分
                            let BF0 = parseInt(ThisCell.split(':')[0]), BF1 = parseInt(ThisCell.split(':')[1]);
                            if (BF0 > BF1) {
                                this.PHBs[M - 1].Vn += 1;
                                this.PHBs[M - 1].RJF += 2;
                                this.PHBs[i - 1].Fn += 1;
                                this.PHBs[i - 1].RJF += 1;
                            }
                            if (BF0 < BF1) {
                                this.PHBs[M - 1].Fn += 1;
                                this.PHBs[M - 1].RJF += 1;
                                this.PHBs[i - 1].Vn += 1;
                                this.PHBs[i - 1].RJF += 2;
                            }
                            this.PHBs[M - 1].XF += BF0;
                            this.PHBs[i - 1].XF += BF1;
                        }
                    }

                for (let i = 0; i < this.PHBs.length; i++) this.PHBs[i].MC = this.PHBs[i].RJF * 1000 + this.PHBs[i].XF;
                this.PHBs.sort(Compare('MC')).reverse();
            }
        }
    }
</script>

<style scoped>
</style>

