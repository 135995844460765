<template>
    <FrontPage v-if="DLed" @FromFrontPage="ProcessFromFrontPage"></FrontPage>
    <PopTitle v-if="ToReg" Title="请准确提供以下信息." @FromPopUp="DLed=true,ToReg=false">
        <FYReg @FromReg="ProcessFromReg"></FYReg>
    </PopTitle>
    <PopTitle v-if="ToLogin" Title="请准确输入登录信息." @FromPopUp="DLed=true,ToLogin=false">
        <LoginFY @FromLoginFY="ProcessFromLoginFY"></LoginFY>
    </PopTitle>
</template>

<script>//用localStorage自动登录：判断是新用户，还是老用户；此处不进行LandTo判断,不考虑小程序的调用，小程序调用应该直接对后台
import {DL} from '@/components/M0_RegLogin/DL'
import FrontPage from "./FrontPage";
import LoginFY from "./M0_RegLogin/LoginFY";
import PopTitle from "./MyPopWin/PopTitle";
import FYReg from "./M0_RegLogin/FYReg";

import { ElMessage } from 'element-plus';
export default {
    name: "AppDL",
    components: {FYReg, PopTitle, LoginFY, FrontPage},
    data() {
        return {
            DLed: false,
            dlData: {
                fyUserName: "",
                fyPassword: "",
                AutoDl: true,
            },
            ToReg: false,
            ToLogin: false
        }
    },

    mounted() {
        let that = this;
        let MyStorage = window.localStorage; //本地存储应用特别事项:第一,数类型均为字符串;第二;并不安全
        if (MyStorage && MyStorage.M1) {
            that.dlData = {
                fyUserName: MyStorage.M1.split("-=_+")[0],
                fyPassword: MyStorage.M1.split("-=_+")[1],
                AutoDl: MyStorage.M1.split("-=_+")[2] === "true"
            };
            if (that.dlData.fyUserName === '游客') DL(that, '直接返回', function () {
                that.DLed = true;
            }); else if (that.dlData.AutoDl) DL(that, '去登录', function () {
                that.DLed = true;
            }); else DL(that, '直接返回', function () {
                that.DLed = true;
            });
        } else DL(that, '直接返回', function () {
            that.DLed = true;
        });

        if (MyStorage && MyStorage.M2) this.$store.commit("ChangeFYArea", JSON.parse(MyStorage.M2).FYArea);
    },
    methods: {
        ProcessFromFrontPage(Val) {
            console.log("ProcessFromFrontPage=", Val);
            this.DLed = false;
            if (Val === 'ToReg') this.ToReg = true;
            if (Val === 'ToLogin') this.ToLogin = true;
        },
        ProcessFromReg(Val) {
            console.log("ProcessFromLoginFY=", Val);
            this.ToLogin = true;
            this.ToReg=false;
            ElMessage('注册成功， 请登录一下试试看！');
        },
        ProcessFromLoginFY(Val) {
            console.log("ProcessFromLoginFY=", Val);
            this.DLed = true;
            this.ToLogin=false;
            ElMessage('登录成功!');
        }
    }
}
</script>
