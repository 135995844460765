<template>
    <div>
        &nbsp;&nbsp;权限资格申请:
        <el-divider/>
        <div class="MLR2" title="权限资格申请">
            <div v-if="$store.state.UserBools.indexOf('活动管理员')>=0" class="XLR MP4 TB">
                活动管理员资格
                <el-icon>
                    <CircleCheck/>
                </el-icon>
            </div>
            <div v-else class="XLR MP4 TB" @click="Show =1">
                活动管理员资格
                <el-icon>
                    <ArrowRight/>
                </el-icon>
            </div>
            <el-divider/>
            <PopTitle v-if="Show===1" Title="活动管理员资格申请" @FromPopUp="Show=-1">
                <QXAsk QX="活动管理员" @FromQXAsk="Show=-1"></QXAsk>
            </PopTitle>

            <div v-if="$store.state.UserBools.indexOf('场地管理员')>=0" class="XLR MP4 TB">
                场地管理员资格
                <el-icon>
                    <CircleCheck/>
                </el-icon>
            </div>
            <div v-else class="XLR MP4 TB" @click="Show =2">
                场地管理员资格
                <el-icon>
                    <ArrowRight/>
                </el-icon>
            </div>
            <el-divider/>
            <PopTitle v-if="Show===2" Title="场地管理员资格申请" @FromPopUp="Show=-1">
                <QXAsk QX="场地管理员" @FromQXAsk="Show=-1"></QXAsk>
            </PopTitle>


            
            
            
            
            
            <div v-if="$store.state.UserBools.indexOf('活动管理员')>=0" class="XLR MP4 TB">
                打擂器管理员资格
                <el-icon>
                    <CircleCheck/>
                </el-icon>
            </div>
            <div v-else class="XLR MP4 TB" @click="Show =3">
                打擂器管理员资格
                <el-icon>
                    <ArrowRight/>
                </el-icon>
            </div>
            <el-divider/>
            <PopTitle v-if="Show===3" Title="打擂器管理员资格申请" @FromPopUp="Show=-1">
                <QXAsk QX="活动管理员" @FromQXAsk="Show=-1"></QXAsk>
            </PopTitle>


            <div v-if="$store.state.UserBools.indexOf('泛约裁判员')>=0" class="XLR MP4 TB">
                泛约裁判员资格
                <el-icon><CircleCheck/></el-icon>
            </div>
            <div v-else class="XLR MP4 TB" @click="Show =4">
                泛约裁判员资格
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
            <PopTitle v-if="Show===4" Title="泛约裁判员资格申请" @FromPopUp="Show=-1">
                <QXAsk QX="泛约裁判员" @FromQXAsk="Show=-1"></QXAsk>
            </PopTitle>


            <div v-if="$store.state.UserBools.indexOf('活动管理员')>=0" class="XLR MP4 TB">
                赛事管理员资格
                <el-icon>
                    <CircleCheck/>
                </el-icon>
            </div>
            <div v-else class="XLR MP4 TB" @click="Show =5">
                赛事管理员资格
                <el-icon>
                    <ArrowRight/>
                </el-icon>
            </div>
            <!--cell v-if="1===2 && $store.state.UserBools.indexOf('制售会员卡')>=0" title="制售会员/优惠卡资格" value="☑"></cell>
            <Cell v-else title="制售会员/优惠卡资格" is-link arrow-direction="right" @click.native="Show16 = true"></Cell>
            <PopTitle v-if="Show16" Title="制售会员/优惠卡资格申请" @FromPopUp="Show16=false">
              <QXAsk QX="制售会员卡" @FromQXAsk="Show16=false"></QXAsk>
            </PopTitle>


            <cell v-if="$store.state.UserBools.indexOf('社交工具')>=0" title="社交工具应用项目创建发布资格" value="☑"></cell>
            <Cell v-else title="社交工具应用项目创建发布资格" is-link arrow-direction="right" @click.native="Show13 = true"></Cell>
            <PopTitle v-if="Show13" Title="社交工具应用项目创建发布资格申请"
                     @FromPopUp="Show13=false">
              <QXAsk QX="社交工具" @FromQXAsk="Show13=false"></QXAsk>
            </PopTitle>

      <cell v-if="$store.state.UserBools.indexOf('视频信息')>=0" title="视频信息上传发布资格" value="☑"></cell>
            <Cell v-else title="视频信息上传发布资格" is-link arrow-direction="right" @click.native="Show15 = true"></Cell>
            <PopTitle v-if="Show15" Title="视频信息上传发布资格申请"                @FromPopUp="Show15=false">
              <QXAsk QX="视频信息" @FromQXAsk="Show15=false"></QXAsk>
            </PopTitle>


            <cell v-if="$store.state.UserBools.indexOf('家谱管理员')>=0" title="家谱管理员资格" value="☑"></cell>
            <Cell v-else title="家谱管理员资格" is-link arrow-direction="right" @click.native="Show11 = true"></Cell>
            <PopTitle v-if="Show11" Title="家谱管理员资格申请"
                     @FromPopUp="Show11=false">
              <QXAsk QX="家谱管理员" @FromQXAsk="Show11=false"></QXAsk>
            </PopTitle-->
        </div>

        <el-divider/>
        <PopTitle v-if="Show===5" Title="泛约裁判员资格申请" @FromPopUp="Show=-1">
            <QXAsk QX="活动管理员" @FromQXAsk="Show=-1"></QXAsk>
        </PopTitle>
    </div>
</template>

<script>

    import PopTitle from "../MyPopWin/PopTitle";
    import QXAsk from "./QXAsk";

    export default {
        name: "QXZG",
        components: {QXAsk, PopTitle},
        data() {
            return {
                Show: -1
            }
        }
    }
</script>

<style scoped>

</style>
