<template>
    <div>
        <div class="XLR YC H3" style="padding-top: 1mm">
            <div></div>
            &nbsp;
            <div class="XL YC ">
                <div class="T3 NoWrap LH120">搜索<br>范围</div>
                ：
                <FYArea @FromFYArea="MapTip"></FYArea>
            </div>
            <div><img src="../../../static/gif2/Shout.gif" @click="ShoutBool=true" style="width: 9mm;height: 9mm"></div>
            &nbsp;
        </div>
        <div v-if="ShoutBool" class="XLR YC">&nbsp;
            <input type="text" class="W80" v-model="RealTimeStr" @focus="RealTimeStr=''"/>
            <div class="TB" @click="PutToRealTimeStr">发出</div>&nbsp;
        </div>
        <div v-if="Chats.length>0 || ChatBegin" class="Box5">
            <div class="XLR">
                <div v-if="ChatBegin">☞{{He}}</div>&nbsp;
                <div v-if="item.Who!==''">
                    <div v-for="(item,index) in Chats" class="W90 MT2" :key="index">
                        <div class="XLR T3">
                            <img :src="'UserUpload/1/S' + item.UserLogo" class="AvatarWH2">
                            <div class="W80">
                                <div class="T1 TH">{{item.PubTime2}}</div>
                                <div class="T3">{{item.Words}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="TH" @click="CloseChat">✕</div>
            </div>
            <div class="XLR">&nbsp;
                <input type="text" class="BoxGray1 W80" v-model="MyWord" @focus="MyWord=''" style="color: #434343"/>
                <div class="TH" @click="SendMyWord">发送</div>
            </div>
        </div>
        <div class="Box5">
            <div v-if="Show7" class="AlignC"><br>
                <img src="/static/gif2/Loading.gif" class="W12"><br>
                正在载入地图...
            </div>
            <div ref="MapContainer" style="width:99vw;height:80vh;"></div>
        </div>
        <div class="TF T3">备注：本应用识别的用户地址来自注册信息中的常驻地，如果您未输入将不能准确定位。</div>
        <br>
        <WXLink v-if="$store.state.UserName!=='游客'" WTitle="长江长江，我是黄河；看到请回复 !"
                WText="快打开泛约地图，看看是谁在呼叫？" WVisitor="RealTime" Pic="static/img/SY/Map1.png"></WXLink>
    
    </div>
</template>

<script>
    import {
        AutoAlert,
        GetProvincialCapital,
        myMongoDBPost,
        StampToTimeStr,
        Tofixed,
        UrlFrom
    } from '@/components/SharedVues/Shared0.js';
    import WXLink from "./WXLink";
    import SubEntrance from "@/components/SharedVues/SubEntrance";
    import FYArea from "@/components/SharedVues/FYArea";

    export default {
        name: "RealTime",
        components: {FYArea, SubEntrance, WXLink},
        data() {
            return {
                MyMap: null,
                Timer: null,
                ShowPoppup: true,
                ShoutBool: false,
                RealTimeStr: '',

                ChatBegin: false,
                Chats: [],
                He: '', //当前聊天对象的泛约昵称
                MyWord: '',

                Markers: [],

                Show7: false,
                MapLoadedBool: 0,
                CurrCity: ''
            }
        },
        beforeMount() {
            this.Timer = setInterval(this.TimerFun, 5000);//每5秒访问一次服务器,以获取最新实时呼叫用户列表数据
        },
        beforeUnmount() {
            if (this.Timer) { //如果定时器还在运行 或者直接关闭，不用判断
                clearInterval(this.Timer); //关闭
            }
        },
        computed: {
            CurrFYArea() {
                return this.$store.state.FYArea
            }
        },
        watch: {
            CurrFYArea(Val) {
                this.CityLocation(Val);
            },
            MapLoadedBool(Val) {
                if (Val > 0) this.Show7 = false;
            }
        },
        mounted() {
            let that = this;
            this.MapInit();
            this.getMyLocation();
            this.CityLocation(this.$store.state.FYArea);
            this.AllUsersShowMarker();
            document.getElementById('TitleId').innerText = '泛约：实时在线地图找人';
            if (this.$store.state.WXId === '未关注') AutoAlert('欢迎您访问泛约免费邀约服务平台', '请长按上方二维码关注，然后注册登录!');
        },
        methods: {
            MapTip(V) {
                let Val = this.$store.state.FYArea;
                if (Val.charAt(0) === 'D') AutoAlert('当前仅支持定位到城市', '若要到区县，请手动操作！');
            },
            CityLocation(CurrFYArea) {
                let that = this, marker = null, Zoom = 9, CurrProvince = CurrFYArea.split(":")[1];
                this.CurrCity = CurrFYArea.split(":")[2];
                if (CurrFYArea.split(":")[0] === 'N') {
                    this.CurrCity = "北京市";
                    Zoom = 3;
                } else if (CurrFYArea.split(":")[0] === 'P') this.CurrCity = GetProvincialCapital(CurrProvince);
                let citylocation = new qq.maps.CityService({//腾讯地图的城市定位服务
                    complete: function (results) {
                        that.MyMap.setCenter(results.detail.latLng);
                        that.MyMap.setZoom(Zoom);
                        if (marker !== null) {
                            marker.setMap(null);
                        }
                        marker = new qq.maps.Marker({
                            map: that.MyMap,
                            position: results.detail.latLng
                        });
                        that.Markers.push(marker);
                    }
                });
                citylocation.searchCityByName(this.CurrCity);
            },
            MapInit(Lati, Longi) {//1.初始化地图MyMap,2.初始显示本人定位
                let that = this;
                if (that.MapLoadedBool === 0) that.Show7 = true;
                let CenterInit = new qq.maps.LatLng(Lati, Longi);//39.914850, 116.403765);
                that.MyMap = new qq.maps.Map(that.$refs.MapContainer, {center: CenterInit, zoom: 13});
                that.MapLoadedBool = that.MyMap.zoom;
            },
            getMyLocation() {
                let that = this;
                if (UrlFrom() === '微信') {
                    wx.getLocation({
                        type: 'gcj02', // 默认为wgs84的gps坐标(不准确)，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                        success: function (res) {
                            //that.accuracy = res.accuracy;
                            that.MapInit(res.latitude, res.longitude);
                            that.ShowMyPosition(res.latitude, res.longitude);
                        },
                        fail: function (err) {
                            //console.log("接口调用失败err=" + err);
                        }
                    })
                } else {
                    let geolocation = new qq.maps.Geolocation("V66BZ-BUILD-32I4O-PPVML-TRFA6-FXF4H", "泛约在线地图");//https://lbs.qq.com/console/myquota.html?key=V66BZ-BUILD-32I4O-PPVML-TRFA6-FXF4H
                    if (geolocation)
                        geolocation.getLocation(//它的两个参数都是回调函数
                            function (position) {
                                //that.accuracy = position.accuracy;
                                that.MapInit(position.lat, position.lng);
                                that.ShowMyPosition(position.lat, position.lng);
                            },
                            function () {
                                //console.log("定位失败");
                                that.getMyLocation();//定位失败再请求定位，直至成功
                            }); else alert('不支持地图定位!');//注意不是geolocation.getIpLocation
                }
            },
            ShowMyPosition(Lati, Longi) {
                let that = this;
                let aObj = {
                    UPTJ: {UserName: that.$store.state.UserName},
                    UPObj: {Longi: Longi, Lati: Lati}
                };
                if (this.$store.state.UserName !== '游客') myMongoDBPost('UpdateDoc/fyuser0', aObj, function () {
                });
                let MyLatLng = new qq.maps.LatLng(Lati, Longi);
                let marker1 = new qq.maps.Marker({
                    position: MyLatLng,
                    map: that.MyMap
                });
                let marker2 = new qq.maps.Label({
                    position: MyLatLng,
                    map: that.MyMap,
                    content: that.$store.state.UserName
                });
                that.Markers.push(marker1);
                that.Markers.push(marker2);
            },

            SendMyWord() {
                let that = this, aObj = {
                    PubTime: (new Date()).getTime(),
                    FromUserName: that.$store.state.UserName,
                    FromUserLogo: that.$store.state.UserLogo,
                    ToUserName: that.He,
                    Words: that.MyWord, //聊天内容
                    Readed: 0
                };
                that.ChatBegin = false;
                myMongoDBPost("PutDoc/chats", aObj, function (Docs) {
                    that.MyWord = "";
                    that.CloseChat();
                })
            },
            PutToRealTimeStr() {
                let that = this;
                let aObj = {
                    UPTJ: {UserName: that.$store.state.UserName},
                    UPObj: {RealTimeStr: that.RealTimeStr}
                };
                if (this.$store.state.UserName !== '游客') myMongoDBPost('UpdateDoc/fyuser0', aObj, function () {
                    that.ShoutBool = false;
                }); else AutoAlert('亲，游客发送无效', '赶快注册登录吧！');
            },

            CloseChat() {
                let that = this;
                let iN = 0;
                for (let i = 0, len = that.Chats.length; i < len; i++)
                    (function (i) {
                        let aObj = {
                            UPTJ: {FromUserName: that.Who, PubTime: that.Chats[i].PubTime, Words: that.Chats[i].Words},
                            UPObj: {Readed: 1}
                        };
                        myMongoDBPost('UpdateDoc/Chats', aObj, function () {
                            iN++;
                            if (iN === that.Chats.length) that.Chats = [];
                        })
                    })(i) //采用匿名函数闭包的方法解决循环内有回调的问题
                that.ChatBegin = false;
            },
            GetChats() { //获取当前聊天用户发给我的聊天内容
                let that = this;
                myMongoDBPost("GetDocs/Chats", {
                    //FromUserName: that.He,
                    ToUserName: that.$store.state.UserName,
                    Readed: 0
                }, function (Docs) {
                    that.Chats = [];
                    let Ds = [];
                    for (let i = 0, len = Docs.length; i < len; i++)
                        Ds.push({
                            Who: Docs[i].FromUserName,
                            UserLogo: Docs[i].FromUserLogo,
                            PubTime: Docs[i].PubTime,
                            PubTime2: StampToTimeStr(Docs[i].PubTime),
                            Words: Docs[i].Words
                        });
                    if (Ds.length > 0) {
                        that.He = Ds[0].Who;
                        that.Chats = Ds;
                    }
                    //console.log(Docs);
                    //console.log("that.He=" + that.He);
                    //console.log(that.Chats);
                });

            },
            ShowMarker(Lati, Longi, UserLogo, He,Sex, RealTimeStr) {
                if (RealTimeStr.length > 2) {
                    let that = this;
                    let MyLatLng = new qq.maps.LatLng(Lati, Longi);

                    let marker = new qq.maps.Marker({
                        position: MyLatLng,
                        map: that.MyMap
                    });
                    let anchor = new qq.maps.Point(10, 25),
                        origin = new qq.maps.Point(0, 0),
                        size = new qq.maps.Size(10, 25),
                        markerIcon = new qq.maps.MarkerImage("http://www.fy135.vip/UserUpload/1/"+(Sex==='男' ? '':'wo')+"man.png", null, origin, anchor, size);
                    marker.setIcon(markerIcon);

                    let marker2 = new qq.maps.Label({
                        position: MyLatLng,
                        map: that.MyMap,
                        content: RealTimeStr
                    });
                    qq.maps.event.addListener(marker, 'click', function () {
                        that.He = He;
                        that.GetChats();
                        that.ChatBegin = true;
                    });
                    that.Markers.push(marker);
                    that.Markers.push(marker2);
                }
            },
            AllUsersShowMarker() {
                let that = this, TJ = {"MiniProgStamp": {$gt: (new Date()).getTime() - 20000}};

                myMongoDBPost("GetDocs/fyuser0", TJ, function (Docs) {//调出所有当前在地图上喊话的用户
                    for (let i = 0, len = Docs.length; i < len; i++)
                        (function (i) {
                            that.ShowMarker(Docs[i].Lati, Docs[i].Longi, Docs[i].UserLogo, Docs[i].UserName,Docs[i].Sex, Docs[i].RealTimeStr);
                        })(i) //采用匿名函数闭包的方法解决循环内有回调的问题
                })
            },

            TimerFun() {
                let that = this;
                that.GetChats();
                that.AllUsersShowMarker();
                let UrlObj = {UPTJ: {UserName: this.$store.state.UserName}, UPObj: {MiniProgStamp: (new Date()).getTime()}};
                if (this.$store.state.UserName !== '游客') myMongoDBPost("UpdateDoc/fyuser0", UrlObj, function (data) {
                });

                for (let i = 0, len = that.Markers.length; i < len; i++)
                    (function (i) {
                        that.Markers[i].setMap(null);
                        if (i === that.Markers.length - 1) that.Markers = [];
                    })(i) //采用匿名函数闭包的方法解决循环内有回调的问题
            },
            Tofixed(x, y) {
                return Tofixed(x, y)
            }
        }
    }
</script>

<style scoped>
    .W12 {
        width: 12vw;
        }
    
    .W80 {
        width: 80vw
        }
</style>
