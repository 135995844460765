<template>
    <div class="XLR YC MT2">
        <div></div>
        <img src="../../../static/gif2/ArrowR.gif" style="width: 2rem;">
        <div v-if="$store.state.WXId === '未关注'" class="XLR YC">
            <img :src="'UserUpload/static/CZSM/Two9kb.jpg'" class="WHbb">→
            <div class="AN ANYellow" @click="$emit('FromSubEntrance','ToReg')">注册</div>
                                                                         →
            <div class="AN ANGreen" @click="$emit('FromSubEntrance','ToLogin')">登录</div>
        </div>
        <div v-else class="XLR YC">
            <div v-if="!Reged" class="XLR YC">
                <div class="AN ANYellow" @click="$emit('FromSubEntrance','ToReg')">注册</div>
                →
                <div class="AN ANGreen" @click="$emit('FromSubEntrance','ToLogin')">登录</div>
            </div>
            <div v-else class="AN ANGreen" @click="$emit('FromSubEntrance','ToLogin')">登录</div>
        </div>
        <div></div>
        &nbsp;
    </div>
</template>

<script>
    import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'

    export default {
        name: "SubEntrance",
        props: ['ShowMode'],
        data() {
            return {
                Reged: false,
            }
        },
        mounted() {//进来时已是游客用户
            let that = this;
            console.log("SubEntrance");
            if (this.$store.state.WXId === '未关注')
                alert('欢迎光临泛约大厅，请先关注并置顶，然后注册登录!');
            else if (this.$store.state.WXId !== '') myMongoDBPost("GetDocs/fyuser0", {WXId: this.$store.state.WXId}, function (Docs) {
                that.Reged = (Docs.length > 0);
                if (that.Reged && that.$store.state.LandTo !== 'LoginFY') alert('应用掉线，您需要完成一次登录操作!');
            });
        },
        unmounted() {
            console.log("退出SubEntrance")
        },
    }
</script>

<style scoped>

</style>
