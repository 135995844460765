<template>
    <div class="MaskCss" :style="'z-index:'+MZindex"></div>
    <transition>
        <div v-if="Show7" class="PopWin" :style="'z-index:'+(Zindex+1)+';top:'+NewTop+'px'">
            <slot></slot>
        </div>
    </transition>
</template>
<script>
    export default {
        name: "PopBlank",
        data() {
            return {
                Show7: false,
                MZindex: 0,
                Zindex: 0,
                NewTop: 0
            }
        },
        mounted() {
            this.NewTop = window.scrollTop || document.documentElement.scrollTop;
            this.$store.commit('ChangeCurrZindex', this.$store.state.CurrZindex + 50);
            this.Zindex = this.$store.state.CurrZindex;
            this.MZindex = this.$store.state.CurrZindex - 2;
            this.Show7 = true;
        },
        beforeUnmount() {
            document.body.classList.remove('body-locked');
            this.$store.commit('ChangeCurrZindex', this.$store.state.CurrZindex - 50);//切记10000和2000改小了,还不行
        },
    }
</script>
<style>
</style>

