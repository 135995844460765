<template>
        <PopUpDown :Title="'请选择：'+(VP==='1' ? '照片':'视频')+'来源'" :ANs="1" Pos="ttb" @FromPop5="QUIT(0)">
            <div class="XLR">&nbsp;
                <div v-if="VP==='1'">
                    <div class="BDCircle Css11 XYC">拍照</div>
                    <input ref="File11" type="file" name="upload" class="Css22" accept="image/*" data-role="none"
                           capture="camera" @change="GetFile('1','File11')"/>
                </div>
                <div v-else>
                    <div class="BDCircle Css11 XYC">录制</div>
                    <input ref="File21" type="file" name="upload" class="Css22" accept="video/*" data-role="none"
                           capture="camera" @change="GetFile('2','File21')"/>
                </div>
                &nbsp;
                <div v-if="VP==='1'">
                    <div class="BDCircle Css11 XYC">选择<br>照片</div>
                    <input ref="File12" type="file" name="upload" class="Css22" accept="image/*;" data-role="none"
                           @change="GetFile('1','File12')"/>
                </div>
                <div v-else>
                    <div class="BDCircle Css11 XYC">选择<br>视频</div>
                    <input ref="File22" type="file" name="upload" class="Css22" accept="video/*;" data-role="none"
                           @change="GetFile('2','File22')"/>
                </div>
                &nbsp;
            </div>
            <div v-if="1===2" class="XLR YC">&nbsp;
                <div class="XLR YC">
                    <div class="TF">
                        引用码：
                        <div class="TC">
                            <input type="text" class="W20" v-model="QuoteCodeStr" style="height: 2.2rem"/>
                        </div>
                    </div>
                    <div class="T8 TF">→</div>
                    <div class="BDCircle Css11 XYC" @click="Quote">引用</div>
                </div>
                &nbsp;
            </div>
            <div class="MP4 TE"><p>重要提示：如果“{{VP==='1' ? '拍照':'录制'}}”时出现闪退问题(部分手机)，请改用“选择”方式上传！</p></div>
            <br>
        </PopUpDown>
        <PopUpDown v-if="Show0" Title="文件上传中" Pos="btt" @FromPop5="SetShow0()">
            <div class="MP4">
                正在上传:<br>
                {{">" + CurrFile + ":" + Percent}}%<br>

                <div class="demo-progress">
                    <el-progress :text-inside="true" :stroke-width="20" :percentage="Percent"/>
                </div>


                <div v-if="CurrFileSize>5" class="XLR YC MT2">
                    大小：{{CurrFileSize}}M
                    <div>
                        所需泛约币数量：{{parseInt(CurrFileSize/5)}}
                    </div>
                </div>
            </div>

        </PopUpDown>
        <!--image/*;加不加分号是不一样的  如果不加分号,则可以同时调出拍照和文件选择,但拍照老是闪退-->
</template>

<script>
    //data-role="none" ： 阻止框架对元素进行渲染，使元素以html原生的状态显示，主要用于form元素。
    import {VideoThumb, DoUpload2, fileToCanvasToBlob} from '@/components/MyPVTs/UpLoadShare.js'
    import {
        myMongoDBPost,
        myDataFileGet,
        myVodpost,
        TransferZX,
        getUniqueCode,
        Tofixed
    } from '@/components/SharedVues/Shared0.js';
    import PopTitle from "../MyPopWin/PopTitle";
    import PopUpDown from "../MyPopWin/PopUpDown";
    import axios from 'axios'
    //import TcVod from 'vod-js-sdk-v6';
    //
    export default {
        name: "UpFiles1",
        components: {PopUpDown, PopTitle},
        props: ['VP'],
        data() {
            return {
                ShowA: true,
                ShowB: true,
                Percent: 1,
                PercentN: 0,//同一个Percent被监测的次数
                Show0: false,
                Timer: 0,
                CurrFile: '',
                CurrFileSize: 0,
                ShowPop: true,
                Clicked: 0,
                QuoteCodeStr: ''
            }
        },
        methods: {
            QUIT(Bool) {
                if (Bool === 1) alert('您打开了上传文件窗口,却又未选择任何文件！');
                if (Bool === 2) alert('您的上传操作类型和您实际选择的照片视频类型不一致!');
                if (Bool === 3) alert("抱歉，请将视频控制在超清：25秒，高清：10秒以内!");
                this.ShowPop = false;
                this.$emit('FromU1', '未选择');
            },
            GetFile(VPBool, FileId) {
                //1.获取file
                let fileInput;//=document.getElementById(FileId); //1.获取input文件选择标签
                if (FileId==='File11') fileInput=this.$refs.File11;
                if (FileId==='File12') fileInput=this.$refs.File12;
                if (FileId==='File21') fileInput=this.$refs.File21;
                if (FileId==='File22') fileInput=this.$refs.File22;
                let Files = fileInput.files;//files＝待上传的照片或视频文件
                if (Files[0] === undefined) return this.QUIT(1);
                if (Files.length <= 0) return this.QUIT(1);
                let asd = Files[0].name.split('.');//判断选择文件是否合法,部分终端需要
                let Ext = asd[asd.length - 1].toLowerCase();
                let Continue = (VPBool === '1' && (Ext === 'jpg' || Ext === 'jpeg' || Ext === 'png' || Ext === 'gif')) ||
                    (VPBool === '2' && (Ext === 'mp4' || Ext === '3gp' || Ext === 'mov' || Ext === 'wmv') || VPBool === '3' && (Ext === 'txt'));
                if (!Continue) return this.QUIT(2);
                this.CurrFileSize = (Files[0].size / (1024 * 1024)).toFixed(2);
                if (this.CurrFileSize > 200) return this.QUIT(3);
                
                let that = this, NeedZX = Math.trunc(this.CurrFileSize / 5);
                if (NeedZX > that.$store.state.ZX) alert("本次上传需要" + NeedZX + "个泛约币"+'。您现有泛约币' + that.$store.state.ZX + '个，请先充值！');
                else if (NeedZX > 0) {
                    let YN = confirm("本次上传需要" + NeedZX + "个泛约币.还要继续吗？");
                    if (YN) TransferZX(that, that.$store.state.UserName, that.$store.state.HTM0, NeedZX, function (asd) {
                        DoUp0();
                    });
                } else DoUp0();

                function DoUp0() {
                    //2.文件全名
                    let asd2 = Files[0].name.split('.');
                    let asd3 = asd2[asd2.length - 1];
                    if (asd3.toLowerCase() === 'jpeg') asd3 = 'jpg';
                    that.CurrFile = 'FY' + getUniqueCode() + "." + asd3;//构造文件全名
                    that.G_UpBool = '开始';

                    //function DoUp(file, FullFileName2) {
                    //myVodpost("getServerSignature", {}, function (Signature) {
                    function getSignature() {
                        return axios.post("MyVod.js/getServerSignature").then(function (response) {
                            return response.data;
                        })
                    };
                    const tcVod = new TcVod.default({getSignature: getSignature, appId: 1256437366});
                    const uploader = tcVod.upload({
                        mediaFile: Files[0],
                        mediaName: that.CurrFile
                    });// 媒体文件（视频或音频或图片），类型为 File
                    uploader.on('media_progress', function (info) {
                        that.Percent = info.percent * 100;
                    });
                    uploader.done().then(function (doneResult) {
                        that.Show0 = false;
                        that.$emit('FromU1',doneResult.fileId);
                    }).catch(function (err) {
                    });
                    that.Show0 = true;
                }
            },

            SetShow0() {
                this.Show0 = false;
                this.$emit('FromU1', '未选择')
            },
            Quote() {
                let that = this;
                let i = 0, m = '';
                if (that.VP === '1') {
                    if (that.QuoteCodeStr.indexOf('P') >= 0) {
                        i = parseInt(that.QuoteCodeStr.split("P")[0]);
                        myMongoDBPost("GetDocs/Objs", {ToolM: '共享相册', QuoteCode: i}, function (Docs) {
                            let j = 0, k = '';
                            if (Docs.length === 0) k = '!'; else {
                                j = parseInt(that.QuoteCodeStr.split("P")[1]);
                                if (j >= Docs[0].Pics.length) k = '!!'; else that.$emit('FromU1', Docs[0].Pics[j]);
                            }
                            if (k !== '') {
                                alert('引用码有误，请重新输入' + k);
                                that.$emit('FromU1', '未选择');
                            }
                        })
                    } else m = '.'
                }

                if (that.VP === '2') {
                    if (that.QuoteCodeStr.indexOf('V') >= 0) {
                        i = parseInt(that.QuoteCodeStr.split("V")[0]);
                        myMongoDBPost("GetDocs/Objs", {ToolM: '共享相册', QuoteCode: i}, function (Docs) {
                            let j = 0, k = '';
                            if (Docs.length === 0) k = '!'; else {
                                j = parseInt(that.QuoteCodeStr.split("V")[1]);
                                if (j >= Docs[0].Videos.length) k = '!!'; else that.$emit('FromU1', Docs[0].Videos[j]);
                            }
                            if (k !== '') {
                                alert('引用码有误，请重新输入' + k);
                                that.$emit('FromU1', '未选择');
                            }
                        })
                    } else m = '。'
                }
                if (m !== '') {
                    alert('引用码有误，请重新输入' + m);
                    that.$emit('FromU1', '未选择');
                }
            }
        }
    }
</script>

<style scoped>
    .demo-progress .el-progress--line {
        margin-bottom: 15px;
        max-width: 600px;
    }

    .Css00 {
        background: #000000;
        border: 1px solid #8b8b8b;
        position: absolute;
        left: 20vw;
        top: 10vh;
        width: 60vw;
        height: 30vh;
    }

    .Css11 {
        color: #ffb004;
        background: white;
        border: 1px solid #ffb004;
        width: 1.5cm;
        height: 1.5cm;
    }

    .Css22 {
        position: relative;
        top: -1.2cm;
        width: 1.5cm;
        height: 1.2cm;
        background: white;
        overflow: hidden;
        opacity: 0.01
    }
</style>
