<template>
    <div v-if="Loaded">
        <div v-if="$store.state.UserName === '游客'">
            <br>
            <el-divider><span class="TE">注册登录后，下列项目自动激活</span></el-divider>
            <SideMenu :Menus="Menus" :Begin="BeginN" @SideMenuR="SetCurrSideSele">
                <div v-for="(Item, index2) in Items[CurrSideSele].itemList2" :key="index2" class="DispIB">
                    <div class="AlignC PA2 TF" @click="Alert('请先注册或登录!')">
                        <ShowIcon T="TF" :ItemStr="Item"></ShowIcon>
                        <div class="T3">{{Item.split(':')[1]}}</div>
                    </div>
                </div>
            </SideMenu>
        </div>
        <SideMenu v-else :Menus="Menus" :Begin="BeginN" @SideMenuR="SetCurrSideSele">
            <div v-for="(Item, index2) in Items[CurrSideSele].itemList2" :key="index2" class="DispIB PA2">
                <div class="AlignC MB4 TB LH120" style="width: 20vw;">
                    <ShowIcon :T="ColorClass(Item)" :ItemStr="Item"></ShowIcon>
                    <div v-if="Channel==='打擂竞技'">
                        <el-dropdown>
                            <span class="TB el-dropdown-link"><el-icon> <arrow-down/> </el-icon></span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item @click="SetItemStr(Item,1)">泛约共享卡</el-dropdown-item>
                                    <el-dropdown-item @click="SetItemStr(Item,2)">列表选择式邀约</el-dropdown-item>
                                    <el-dropdown-item @click="SetItemStr(Item,3)">设擂•守擂•打擂</el-dropdown-item>
                                    <el-dropdown-item @click="SetItemStr(Item,4)">赛事•活动→管理器</el-dropdown-item>
                                    <el-dropdown-item @click="SetItemStr(Item,5)">约课器（含报名）</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                    <div v-else>
                        <el-dropdown>
                            <span class="TB el-dropdown-link"><el-icon> <arrow-down/> </el-icon></span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item @click="SetItemStr(Item,1)">泛约共享卡</el-dropdown-item>
                                    <el-dropdown-item @click="SetItemStr(Item,2)">列表选择式邀约</el-dropdown-item>
                                    <el-dropdown-item @click="SetItemStr(Item,5)">约课器（含报名）</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                    <div class="TC" style="white-space: nowrap">{{Item.split(':')[1]}}</div>
                </div>
            </div>
            <div class="TG DispIB AlignC" @click="Show2=6">
                <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">
                <div v-if="Channel==='打擂竞技' || Channel==='休闲娱乐'">添加•设置</div>
                <div v-else>项目设置</div>
            </div>
        </SideMenu>
        <PopTitle v-if="Show2===6" :Title="'定制项目۰配置项目信息'" @FromPopUp="RefreshItems">
            <SetItems :Msg2="{Channel:Channel,ItemType:Menus[CurrSideSele]}"></SetItems>
        </PopTitle>
        <PopMenu v-if="Show2 ===1" :Menus="Menus1" :BeginN="0" @FromPopMenu="PopMenuVal">
            <GXCard v-if="SelectedN===0" :ItemStr="ItemStr"></GXCard>
            <GXCard1 v-if="SelectedN===1" :ItemStr="ItemStr"></GXCard1>
            <GXCard2 v-if="SelectedN===2" :ItemStr="ItemStr"></GXCard2>
        </PopMenu>
        <PopMenu v-if="Show2 === 2" :Menus="Menus2" :BeginN="0" @FromPopMenu="PopMenuVal">
            <XMYY v-if="SelectedN===0" :ItemStr="ItemStr"></XMYY>
            <ZY v-if="SelectedN===1"></ZY>
            <BY v-if="SelectedN===2"></BY>
        </PopMenu>
        <PopOneAN v-if="Show2 === 3 " :Title="ItemStr.split(':')[1]+'项目'" @FromPopUp="Show2=0">
            <DLQ :ItemStr="ItemStr"></DLQ>
        </PopOneAN>
        <PopOneAN v-if="Show2 === 4 " @FromPopUp="Show2=0">
            <ActMain :ItemStr="ItemStr"></ActMain>
        </PopOneAN>
        <PopTitle v-if="Show2 === 5 " :Title="ItemStr.split(':')[1]+'项目'" @FromPopUp="Show2=0">
            <YKQ :ItemStr="ItemStr"></YKQ>
        </PopTitle>
    </div>
</template>

<script>
    import SideMenu from "@/components/SharedVues/SideMenu";
    import {AutoAlert2, Compare, GetC_I, GetItemType, myMongoDBPost} from '@/components/SharedVues/Shared0.js'

    import {ItemIcons} from './ItemIcons'
    import XMYY from "../ListYY/XMYY";
    import SetItems from "../ListYY/SetItems";
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import ShowIcon from "./ShowIcon";
    import SitesList from "../M4_Sites/SitesList";
    import GXCard from "../M11_GXCards/GXCard";
    import ZXGL from "../IndependentVues/ZXGL";
    import OpenItem from "../../../Z_ImportantBak/OpenItem";
    import MyDivider from "../SharedVues/MyDivider";
    import PopMenu from "../MyPopWin/PopMenu";
    import GXCard2 from "../M11_GXCards/GXCard2";
    import GXCard1 from "../M11_GXCards/GXCard1";
    import ZY from "../ListYY/ZY";
    import DLQ from "../DLQ/DLQ";
    import ActMain from "../M3_Acts/ActMain";
    import YKQ from "../YKQ/YKQ";
    import BY from "../ListYY/BY";
    import PopOneAN from "../MyPopWin/PopOneAN";

    export default {
        name: "SeleItem",
        components: {
            PopOneAN,
            BY,
            YKQ,
            ActMain,
            DLQ, ZY, GXCard1, GXCard2, PopMenu, MyDivider, OpenItem, ZXGL, GXCard, SitesList, ShowIcon, PopTitle, SideMenu, XMYY, SetItems
        },
        props: ['Channel', 'Url'],
        data() {
            return {
                index1: 0,
                index2: 0,
                ItemStr: '',
                BeginN: 0,
                Loaded: false,
                CurrSideSele: 0,
                aObj: {
                    ChannelName: this.Channel,
                    UserName: this.$store.state.UserName
                },
                Items: [],
                Menus: ["第一项", "第二项"],
                ItemIcons: ItemIcons,

                Show2: 0,
                Menus1: ["人次卡", "时限卡", "VIP卡"],
                Menus2: ["邀约发起", "主约信息", "被约信息"],
                SelectedN: 0
            }
        },
        watch: {
            Show2(Val) {
                if (Val === 0) document.getElementById('TitleId').innerText = '泛约活动服务平台 > 首页';
                else document.getElementById('TitleId').innerText = this.$store.state.UserName + "/" + this.$store.state.ZX + "个币";
            }
        },
        unmounted() {
            console.log("退出SeleItem")
        },
        mounted() {
            console.log("进入SeleItem");
            let that = this, Asd = this.$store.state.LandTo.split("YYSZ");
            if (Asd.length > 1 && this.$store.state.UserName !== '游客') {
                let LandToItemType = Asd[1] === '0' ? 'A000' : GetItemType(Asd[1].substr(0, 4));
                this.$store.commit('ChangeLandTo', '');
                alert(this.aObj.ChannelName + 'LandToItemType=' + LandToItemType);
                myMongoDBPost("GetChannelItems", this.aObj, function (data) {
                    // data.sort(Compare('ItemType'));
                    that.Items = data;
                    that.Loaded = true;
                    that.Menus = [];
                    for (let i = 0, len = data.length; i < len; i++) {
                        that.Menus.push(data[i].ItemType);
                        if (LandToItemType === data[i].ItemType) that.CurrSideSele = i;
                    }
                    that.Show2 = 6;
                })
            } else this.RefreshItems();
        },
        methods: {
            SetCurrSideSele(Val) {
                this.CurrSideSele = Val;
            },
            IconClass(Item) {
                return this.ItemIcons[Item.split(':')[0]] === undefined ? 'iconfont icon-tubiao' : 'iconfont ' + this.ItemIcons[Item.split(':')[0]]
            },
            ColorClass(Item) {
                return (this.Channel === '打擂竞技' || this.Channel === '休闲娱乐') ? 'TB' : (Item.split(':')[2] === '喜好' ? 'TG' : 'TB')
            },
            RefreshItems() {
                let that = this, Url = (this.$store.state.UserName === '游客' ? "GetChannelItems" : this.Url);
                that.Loaded = false;
                that.Show2 = 0;
                console.log(Url)
                console.log(this.aObj)
                myMongoDBPost(Url, this.aObj, function (data) {
                    //data.sort(Compare('ItemType'));
                    that.Items = data;
                    that.Loaded = true;
                    that.Menus = [];
                    for (let i = 0, len = data.length; i < len; i++) that.Menus.push(data[i].ItemType);
                    that.BeginN = that.CurrSideSele;
                    console.log(that.Items)
                });
            },
            PopMenuVal(Val) {
                if (Val === -1) this.Show2 = 0; else this.SelectedN = Val;
            },
            SetItemStr(asd, N) {
                this.ItemStr = asd.split(":")[0] + ":" + asd.split(":")[1];
                this.Show2 = N;
                this.$store.commit('ChangeCurrItemStr', this.ItemStr);
            },
            Alert(Str) {
                alert(Str)
            }
        }
    }
</script>
<style scoped>
    .MB4 {
        margin-bottom: 4mm
        }
</style>
