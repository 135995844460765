<template>
    <div class="XLR YC BGDeepGreen .BDLightGray PA3 TY">
        <div>{{CurrSiteUser.SiteName}}
            <div class="T3 TF">教练：{{CurrSiteUser.Pic2}}</div>
        </div>
        <div class="YC TW MTB2" @click="SetCancel(CurrSiteUser._id)">
            退学注销&nbsp;<el-icon>
            <ArrowRight/>
        </el-icon>
        </div>
    </div>
    <div class="XLR YC PA3 BottGray">
        <div class="W40 AlignC">
            <div class="XL YC BottGray">
                总学时：{{CurrSiteUser.HourN}}
            </div>
            <div class="XLR YC MT2">
                <div class="T3 TF">已用：{{CurrSiteUser.HouredN}}</div>
                <div class="T3 TF">剩余：{{CurrSiteUser.HourN-CurrSiteUser.HouredN}}</div>
            </div>
        </div>
        <div>
            <div class="T3 TF">有效期至：</div>
            <div class=".BDLightGray BD_Radius1 PA2">{{EndDate}}</div>
        </div>
    </div>
    <div class="AlignC MP4">
        <h5>每周约课时间预设</h5>
    </div>
    <div class="XLR YC BottGray">
        <div class=" W16 ">
            <div class="XLR H6vw T1 BottGray">时段
                <div>\</div>
                星期
            </div>
            <div v-for="(item,index) in MyClassTWs" :key="index">
                <div class="Cell XYC H10vw T3">
                    <div>{{item.TW1}}</div>
                    <div>{{item.TW2}}</div>
                </div>
            </div>
        </div>
        <div class="W84 ">
            <div class="XLR  H6vw BottGray">
                <div v-for="(item,index) in ['一','二','三','四','五','六','日']" :key="index">
                    <div class="Cell W12 T4">{{item}}</div>
                </div>
            </div>
            <div class="XLR " v-for="(CellR,index1) in MyClassTWs" :key="index1">
                <div v-for="(CellRC,index2) in CellR.Days" :key="index2">
                    <div class="Cell W12 H10vw" @click="CellClick(index1,index2)">
                        <div v-if="CoacheClassTWs && CoacheClassTWs[index1] && CoacheClassTWs[index1].Days[index2]" class="FullCell BGDeepGreen"></div>
                        <div v-if="index2>4 && CellRC || index2<5 && CellRC" class="FullCell BGBlue"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="MLR2">
        <div v-if="Changed" class="BD_Radius1 BGBlue T5 TW XYC H3 MT2" @click.once="ChangeKBStatus">提 交</div>
        <div v-else class="BD_Radius1 BGLightGray T5 TW XYC H3 MT2">提 交</div>
    </div>
</template>

<script>
    import {myMongoDBPost, StampToDate, CloneObject} from '@/components/SharedVues/Shared0.js'
    import YKQSetup0 from "./YKQSetup0";

    export default {
        name: "YKQSetup3",
        components: {YKQSetup0},
        props: ['CurrSiteUser'],
        data() {
            return {
                CoacheClassTWs: [],
                MyClassTWs: [],
                AllStuTWsNs: [],
                EndDate: null,
                Changed:true
            }
        },
        mounted() {
            this.MyClassTWs = this.CurrSiteUser.ClassTWs;
            this.EndDate = StampToDate(this.CurrSiteUser.EndStamp);
            this.GetCoacheClassTWs();
            this.GetAllStuTWsNs();
        },
        methods: {
            SetCancel() {
                let that = this;
                myMongoDBPost("RemoveDoc/SiteUsers", {_id: this.CurrSiteUser._id}, function (Docs) {
                    that.$emit('FromYKQSetup3', 'ToSetup0')
                })
            },
            GetCoacheClassTWs() {
                let that = this, TJ = {CoacheUser: this.CurrSiteUser.CoacheUser, DocBool: '教练'};
                myMongoDBPost('GetDocs/SiteUsers', TJ, function (Docs) {
                    if (Docs[0] === undefined) {
                        alert('您的教练可能已离职！ 请重新选择教练!');
                        that.SetCancel()
                    } else if (Docs[0].KBStatus === '预设完毕' || Docs[0].KBStatus === '启用') {
                        that.CoacheClassTWs = Docs[0].ClassTWs;
                        that.InitMyClassTWs();
                    } else {
                        alert('您的教练尚未完成预设,无法继续操作!');
                    }
                })
            },
            GetAllStuTWsNs() {
                let that = this, TJ = {CoacheUser: this.CurrSiteUser.CoacheUser, DocBool: '学生'};
                myMongoDBPost('GetDocs/SiteUsers', TJ, function (Docs) {
                    if (Docs.length > 0) {
                        let asd = CloneObject(Docs[0].ClassTWs);
                        for (let i = 0; i < asd.length; i++) for (let j = 0; j < 7; j++)
                            if (asd[i].Days !== undefined) asd[i].Days[j] = 0;//初始化

                        for (let m = 0; m < Docs.length; m++)
                            for (let i = 0; i < Docs[m].ClassTWs.length; i++)
                                for (let j = 0; j < 7; j++)
                                    if (asd[i] !== undefined && Docs[m].ClassTWs[i].Days !== undefined && Docs[m].ClassTWs[i].Days[j]) asd[i].Days[j] += 1;//统计

                        that.AllStuTWsNs = asd;
                    }
                })
            },
            InitMyClassTWs() {
                //console.log(this.MyClassTWs)
                if (this.CoacheClassTWs.length === 0) alert('您的教练尚未完成排课预设！ 学生的可排课时间必须在教练的可排课时间窗里面!');
                else if (this.MyClassTWs.length < 3) alert('课表框架尚未搭建！ 您必须等课程管理员的前序工作完成后,才能开工!'); else {
                    let NewClassTWs = [];
                    if (this.MyClassTWs[0].Days === undefined) {
                        for (let i = 0; i < this.MyClassTWs.length; i++)
                            NewClassTWs.push({
                                TW1: this.MyClassTWs[i].TW1,
                                TW2: this.MyClassTWs[i].TW2,
                                Hn: this.MyClassTWs[i].Hn,
                                Days: [false, false, false, false, false, false, false]
                            });
                        this.MyClassTWs = NewClassTWs;
                    }
                }
            },
            CellClick(index1, index2) {
                let that = this;
                if (this.CoacheClassTWs && this.CoacheClassTWs[index1] && this.CoacheClassTWs[index1].Days[index2]) {
                    this.MyClassTWs[index1].Days[index2] = !this.MyClassTWs[index1].Days[index2];
                    myMongoDBPost('UpdateDoc/SiteUsers', {UPTJ: {_id: this.CurrSiteUser._id}, UPObj: {ClassTWs: this.MyClassTWs}}, function (Docs) {
                        that.GetAllStuTWsNs();
                    })
                } else alert('点击无效,必须在教练允许时段内预设!');
            },
            ChangeKBStatus() {
                let that = this, Obj = {UPTJ: {_id: this.CurrSiteUser._id}, UPObj: {KBStatus: '预设完毕'}};
                myMongoDBPost("UpdateDoc/SiteUsers", Obj, function (Docs) {
                    that.$emit('FromYKQSetup3', '预设完毕')
                })
            }
        }
    }
</script>

<style scoped>

</style>
