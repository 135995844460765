<template>
    <div></div>
</template>

<script>
    import {UrlFrom} from '@/components/SharedVues/Shared0.js'

    export default {
        name: "FYMap",
        data() {
            return {
                Lati: 0,
                Longi: 0
            }
        },
        props: ["Msg", "OP"],
        mounted() {
            let that = this;
            if (UrlFrom() === '微信') {
                wx.getLocation({
                    type: 'gcj02', // 默认为wgs84的gps坐标(不准确)，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                    success: function (res) {
                        if (that.OP === 'Look') {
                            that.Lati = parseFloat(that.Msg.Lati);
                            that.Longi = parseFloat(that.Msg.Longi);
                        } else {
                            that.Lati = res.latitude;
                            that.Longi = res.longitude;
                        }
                        wx.openLocation({
                            latitude: that.Lati, // that.Lati纬度，浮点数，范围为90 ~ -90
                            longitude: that.Longi, // that.Longi经度，浮点数，范围为180 ~ -180。
                            name: that.Msg.Name, // 位置名
                            address: that.Msg.Addr, // 地址详情说明
                            scale: 13, // 地图缩放级别,整形值,范围从1~28。默认为最大
                            infoUrl: 'https://zhidao.baidu.com/question/1900059921664686620.html', // 在查看位置界面底部显示的超链接,可点击跳转,这里只是个示例
                            success: function (res) {
                                //AutoAlert('',JSON.stringify(res));
                                if (res.errMsg === "openLocation:ok") that.$emit("FromFYMap2", {
                                    CanUse: true,
                                    Lati: that.Lati,
                                    Longi: that.Longi
                                }); else
                                    alert('地图定位失败, 腾讯地图openLocation接口调用异常!');
                            }
                        });
                    },
                    fail: function (err) {
                        alert("微信接口调用失败err=" + err);
                    }
                })
            } else alert('非微信环境下，不支持地图定位!')
        }
    }
</script>

<style scoped>
    .W12 {
        width: 12vw;
        }
</style>
