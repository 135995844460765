<template>
    <br>
    <div v-for="(Sites,index1) in Sitess" :key="index1">
        <div class="TC XLR YC MP4">
            <div v-for="(Site,index2) in Sites" :key="index2">
                <div v-if="Site===null">&nbsp;</div>
                <div v-else class="AlignC" @click="SiteAct0(Site)">
                    <ShowP :Pic="Site.Pic" Class="WHcc"></ShowP>
                    <div class="TB">{{Site.SiteShortName}}</div>
                </div>
            </div>
        </div>
    </div>
    <PopOneAN v-if="ShowAct" @FromPopUp="ShowAct=false">
        <Act0Main :ActDoc="ActDoc"></Act0Main>
    </PopOneAN>
</template>

<script>
    import {myMongoDBPost, Compare} from '@/components/SharedVues/Shared0.js'
    import ShowP from "../MyPVTs/ShowP";
    import PopOneAN from "../MyPopWin/PopOneAN";
    import Act0Main from "../M3_Acts/Act0Main";

    export default {
        name: "SDPZ",
        components: {Act0Main, PopOneAN, ShowP},
        props: ['ItemStr'],
        data() {
            return {
                Sitess: [[]],//二维数组
                ActDoc: null,
                ShowAct: false,
            }
        },
        mounted() {
            let that = this, CurrItem = this.ItemStr.split(":")[1];
            let aObj = {City: this.$store.state.UserC, ItemName: CurrItem};
            myMongoDBPost("ReadCitySiteData", aObj, function (Docs) {
                if (Docs && Docs.length > 0) {
                    console.log(Docs)
                    let Sites =Docs.sort(Compare('SiteShortName'));//下边要把Sites改造成1个二维数组,每个1级数组元素中要包含4个场地
                    let M = 0, N = 0;
                    for (let i = 0; i < Sites.length; i++) {
                        that.Sitess[M].push(Sites[i]);
                        N++;
                        if (N === 4) {
                            that.Sitess.push([]);
                            M++;
                            N = 0;
                        }
                    }
                    if (that.Sitess[M].length === 1) that.Sitess[M].push(null, null, null);
                    else if (that.Sitess[M].length === 2) that.Sitess[M].push(null, null);
                    else if (that.Sitess[M].length === 3) that.Sitess[M].push(null);
                } else
                    alert('您所在城市找不到' + CurrItem + '项目的泛约活动场地！');
            });
        },
        methods: {
            SiteAct0(Site) {
                let that = this;
                myMongoDBPost("GetDocs/Acts", {Text: Site.SiteShortName, EndBool: 3,}, function (Docs) {//约球碰撞
                    if (Docs.length > 0) {
                        that.ActDoc = Docs[0];
                        that.ShowAct = true;
                    } else {
                        that.ActDoc = {
                            Index: 0,
                            UserName: that.$store.state.UserName,
                            Certified: that.$store.state.Certified,
                            UserLogo: that.$store.state.UserLogo,

                            Title: Site.SiteShortName + "泛约会员排名争夺赛",//活动名称或标题
                            HoldStyle: '开放式',
                            ActForm: '单打',
                            Scale: '不限人数',//活动规模
                            ScaleVal: -1,// 活动参数:不限人数型=-1;额满为止型=限定人数;特许参加型=纯数字的特许口令
                            ActStyle: '单场地单循环排名赛',
                            ItemStr: that.ItemStr,
                            JFPool: '泛约',
                            BMList: [],

                            Province: that.$store.state.UserP,
                            City: that.$store.state.UserC,
                            DC: that.$store.state.UserDC,

                            Pic: '',
                            Video: '',
                            Text: Site.SiteShortName,
                            Time: 0,
                            EndBool: 3,
                            ScoringMethod: "按胜场次"
                        };
                        myMongoDBPost("PutDoc/Acts", that.ActDoc, function (data) {
                            that.ActDoc._id = data._id;
                            that.ShowAct = true;
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>