<template>
    <div class="WH85vw30vh XYC">CZSM</div>
</template>

<script>
    export default {
        name: "CZSM"
    }
</script>

<style scoped>

</style>