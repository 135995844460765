<template>
    <YKQSetup0 v-if="Show1===0" @FromYKQSetup0="GetCurrSiteUser"></YKQSetup0>
    <div v-else>
        <div class="XLR YC ML2 MR4">
            <div class="AlignC">
                <div class="T3 PA2" @click="$emit('FromYKQ')"> {{ThisYear}}年:第{{ThisWeekN+1}}周</div>
                <div class="BGBlue XLR YC TW PA1">
                    <div></div>
                    <div class="BGBlue" @click="ChangeMonth(-1)">
                        <img src="../../../static/gif2/ArrowL.gif" style="width: 1.5rem;">
                    </div>
                    <div class="BGWhite PA2">
                        <h5>{{ThisMonth===null ? '12':ThisMonth}}月</h5>
                    </div>
                    <div class="BGBlue" @click="ChangeMonth(1)">
                        <img src="../../../static/gif2/ArrowR.gif" style="width: 1.5rem;">
                    </div>
                    <div></div>
                </div>
            </div>
            <div class="YC" @click="OpenSetup">
                <el-icon :size="40" style="color:#1f98ff"><Setting/></el-icon>
            </div>
            <div v-if="CurrSiteUser.DocBool==='课程管理员'" class="XLR YC">
                <div class="ANBlue BD_Radius1 WHbb  XYC LH120 T2" @click="KBStart"> 课表<br>重排</div>&nbsp;
                <div class="ANBlue BD_Radius1 WHbb  XYC LH120 T2" @click="HoursCount"> 课时<br>统计</div>
            </div>
            <div v-if="['教练','学生'].indexOf(CurrSiteUser.DocBool)>=0" class="W32">
                <el-radio-group v-model="Show" @change="ShowChanged(Show,CurrSiteUser.DocBool)">
                    <el-radio class="MT2" :value="1" border>完整课表</el-radio>
                    <el-radio class="MT2" :value="2" border>我的课表</el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="Box41">
            <!--以下是课表-->
            <div class="XLR TC">
                <div class="W16">
                    <img :src="SrcPath1+'LeftTop1.png'" class="Cell W16 H14vw"/>
                    <div class="Cell T4 H10vw LH100" v-for="(item,index) in CurrSiteUser.ClassTWs" :key="index">
                        <div>{{item.TW1}}</div>
                        <div>{{item.TW2}}</div>
                    </div>
                </div>
                <div class="W84">
                    <div class="XLR">
                        <div class="Cell W12 H8vw T6" v-for="(item,index) in ['一','二','三','四','五','六','日']" :key="index">{{item}}</div>
                    </div>
                    <el-carousel indicator-position="none" :loop="false" :autoplay='false' @change="SwiperChange" ref="carousel" :height="SwiperH+'px'"
                                 :initial-index="ThisWeekN" @touchstart.passive="handleTouchStart" @touchend.passive="handleTouchEnd">
                        <el-carousel-item :name="index" v-for="(YearWeek,index) in SwiperDatas" :key="index">
                            <div class="XLR">
                                <div class="Cell W12 H6vw" v-for="(WeekDay,index2) in YearWeek.WeekDays" :key="index2" @click="DateClick(index,index2)">
                                    <div v-if="WeekDay.Bool1===null" class="FullCell T3 BGWhite">{{WeekDay.Day}}</div>
                                    <div v-if="WeekDay.Bool1==='节假日'" class="FullCell T3 BGLightGray">{{WeekDay.Day}}</div>
                                    <div v-if="WeekDay.Bool1==='工作日'" class="FullCell T3 BGWhite">{{WeekDay.Day}}</div>
                                    <div v-if="WeekDay.Bool1==='禁排日'" class="FullCell T3 BGRed">{{WeekDay.Day}}</div>
                                    <div v-if="WeekDay.Bool1==='排课日'" class="FullCell T3 BGWhite">{{WeekDay.Day}}</div>
                                </div>
                            </div>
                            <div class="XLR TW" v-for="(CellR,index2) in YearWeek.Cells" :key="index2">
                                <div class="Cell W12 H10vw" v-for="(CellRC,index3) in CellR" :key="index3">
                                    <div v-if="CellRC.Valid && CellRC.Cn>0 && YearWeek.WeekDays[index3].Bool2==='冻结'" class="FullCell CellBG1" @click="CellClick(index,index2,index3)">
                                        <div class="NoWrap T3">&nbsp; ：{{CellRC.Cn}}</div>
                                        <div class="NoWrap T3">&nbsp; ：{{CellRC.Sn}}</div>
                                    </div>
                                    <div v-if="CellRC.Valid && CellRC.Cn>0 && YearWeek.WeekDays[index3].Bool2==='可变'" class="FullCell CellBG2" @click="CellClick(index,index2,index3)">
                                        <div class="NoWrap T3">&nbsp; ：{{CellRC.Cn}}</div>
                                        <div class="NoWrap T3">&nbsp; ：{{CellRC.Sn}}</div>
                                    </div>
                                    <div v-if="CellRC.NoSs!=='系统禁排' && CellRC.NoSs.length>0" class="FullCell TE LH120 AlignC" @click="CellClick(index,index2,index3)">
                                        禁排<br>{{CellRC.NoSs.length}}
                                    </div>
                                    <div v-if="CellRC.Valid && CellRC.NoSs==='系统禁排'" class="TE T2" @click="CellClick(index,index2,index3)">系统禁排</div>
                                    <div v-if="YearWeek.WeekDays[index3].Bool1===null" class="FullCell BGWhite"></div>
                                    <div v-if="YearWeek.WeekDays[index3].Bool1==='节假日'" class="FullCell BGLightGray">
                                    </div>
                                    <div v-if="YearWeek.WeekDays[index3].Bool1==='工作日'" class="FullCell BGLightGreen">
                                    </div>
                                    <div v-if="YearWeek.WeekDays[index3].Bool1==='禁排日'" class="FullCell BGRed">
                                    </div>
                                    <div v-if="YearWeek.WeekDays[index3].Bool1==='排课日'" class="FullCell BGWhite">
                                    </div>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <PVs :ItemStr="$store.state.DefaultItem" :SiteId="CurrSiteUser.SiteId" ActFrom="YKQ" Key="日常训练" :UpUserName="$store.state.UserName"></PVs>
        <div v-if="CurrSiteUser.ClassTWs && CurrSiteUser.ClassTWs.length>2" class="T3 TF MLR2">
            <p>当前可用台子数量：{{CurrSiteUser.YKMaxN}}个</p>
            <p v-if="CurrSiteUser.DocBool==='课程管理员'">
                使用说明：当发生下列情况之一时，课程管理员应“课表重排”一次：1.教练或学生修改了自己的约课时间模板；2.有学生通过点击有效窗格(彩色)设置了禁排。</p>
            <p v-if=" CurrSiteUser.DocBool==='教练'">使用说明：如果修改了约课时间模板，请务必通知课程管理员。</p>
            <p v-if="CurrSiteUser.DocBool==='学生'">使用说明：如果修改了约课时间模板或通过点击有效窗格(彩色)设置了禁排，请务必通知课程管理员。</p>
        </div>
        <div v-else class="MLR2">
            <p><span class="TE">当前课表框架数据尚未建立。</span>课表创建与应用的基本流程如下：</p>
            <p>1.课程管理员完成排课框架预设；</p>
            <p>2.教练注册登录后加入教练团队并设定空闲时间窗</p>
            <p>3.课程管理员负责填充教练信息。</p>
            <p>4.学生注册登录后,选择自己的教练,并设定自己的可排课时间；</p>
            <p>5.教练设定所带学生的授课方式；</p>
            <p>6.课程管理员设定各学生的总学时后,点击“重排”按钮。</p>
        </div>
        
        <PopBott v-if="Show===3">
            <div class="XR" @click="Show=1" style="margin: 15px 20px 0 0 ;">
                <el-icon>
                    <CircleClose/>
                </el-icon>
            </div>
            <table class="MT2">
                <tr>
                    <th rowspan="2">教练</th>
                    <th colspan="2">1对1</th>
                    <th colspan="2">1对2</th>
                    <th colspan="2">1对n</th>
                    <th rowspan="2">总学时</th>
                </tr>
                <tr>
                    <th>人次</th>
                    <th>学时</th>
                    <th>人次</th>
                    <th>学时</th>
                    <th>人次</th>
                    <th>学时</th>
                </tr>
                <tr v-for="(item,index) in AllCoaches" :key="index">
                    <td>{{item.Coache}}</td>
                    <td>{{item.Stu11N}}</td>
                    <td>{{item.Stu11H}}</td>
                    <td>{{item.Stu12N}}</td>
                    <td>{{item.Stu12H}}</td>
                    <td>{{item.Stu1nN}}</td>
                    <td>{{item.Stu1nH}}</td>
                    <td>{{item.SumHn}}</td>
                </tr>
            </table>
            <div class="XLR"> ☝</div>
        </PopBott>
        <PopBott v-if="ShowLandscape">
            <div class="XR" @click="ShowLandscape = false" style="margin: 10px 10px 0 0 ;">
                <el-icon size="60">
                    <CircleClose/>
                </el-icon>
            </div>
            <div v-if="CurrSiteUser.DocBool==='学生'" class="XL YC NoWrap PA1">
                <div></div>
                当前时间窗：&nbsp;
                <el-radio-group v-model="aBool" @change="SetMyNo">
                    <el-radio-button label="可以排课" :value="true"/>
                    <el-radio-button label="不能排课" :value="false"/>
                </el-radio-group>
                <div></div>&nbsp;
            </div>
            <div v-if="CurrSiteUser.DocBool==='课程管理员'" class="XLR YC NoWrap PA1">
                <div></div>
                当前时间窗:
                <el-radio-group v-model="aBool" @change="SetMyNo">
                    <el-radio-button label="允许排课" :value="true"/>
                    <el-radio-button label="禁止排课" :value="false"/>
                </el-radio-group>
                <div></div>&nbsp;
            </div>
            <div v-for="(CC,index4) in CurrCell.CSs" :key="index4">
                <div class="W50 XL BottGray"> &nbsp;&nbsp;{{CC.Coache}}&nbsp;&nbsp;</div>
                <div class="MP4 DispIB NoWrap" v-for="(item,index) in CC.Students" :key="index">{{item}}</div>
            </div>
        </PopBott>
        <YKQSetup1 v-if="Show1===1" :CurrSiteUser="CurrSiteUser" @FromYKQSetup1="DoFromYKQSetup1"></YKQSetup1>
        <PopTitle v-if="Show1===2" :Title="'教练:'+CurrSiteUser.Pic2" @FromPopUp="Show1=-1">
            <YKQSetup2 :CurrSiteUser="CurrSiteUser" @FromYKQSetup2="DoFromYKQSetup23"></YKQSetup2>
        </PopTitle>
        <PopTitle v-if="Show1===3" :Title="'学生:'+CurrSiteUser.StudentUser" @FromPopUp="Show1=-1">
            <YKQSetup3 :CurrSiteUser="CurrSiteUser" @FromYKQSetup3="DoFromYKQSetup23"></YKQSetup3>
        </PopTitle>
    </div>
</template>

<script>
    import {myMongoDBPost, StampToDate, DateStr2, CloneObject} from '../SharedVues/Shared0.js'
    import PopTitle from "../MyPopWin/PopTitle";
    import YKQSetup0 from "./YKQSetup0";
    import SubEntrance from "../SharedVues/SubEntrance";
    import YKQSetup1 from "./YKQSetup1";
    import YKQSetup2 from "./YKQSetup2";
    import YKQSetup3 from "./YKQSetup3";
    import {ElMessage} from 'element-plus';
    import PopBott from "../MyPopWin/PopBott";
    import PVs from "../MyPVTs/PVs";

    export default {
        name: "YKQ",
        components: {PVs, PopBott, YKQSetup3, YKQSetup2, YKQSetup1, SubEntrance, YKQSetup0, PopTitle},
        props: ['ItemStr'],
        data() {
            return {
                startX: 0,
                endX: 0,
                Show1: -1,
                CurrSiteUser: {},
                SwiperDatas: [],
                ThisYear: 2022,
                ThisMonth: 1,
                ThisWeekN: 0,
                SwiperH: 100,
                NowWeekN: 0,
                CurrCellIndex: {},
                CurrCell: {},//教练或学生用户点击时，当前Cell的位置。每个cell的数据为: {Cn:当前Cell教练数量:'',Sn:当前Cell学生数量,index4:-1,CSs:[{Coache:??,Students:[]}],NoSs:[]},
                SrcPath1: 'http://www.fy135.vip/UserUpload/static/Img/',
                Show: 1,
                AllStudents: [],
                ShowLandscape: false,
                AllCoaches: [],
                aBool: false,
                Role: '',
                CurrCAdminUser: ''
            }
        },
        mounted() {
            this.GetCurrSiteUser();
        },
        methods: {
            handleTouchStart(event) {
                this.startX = event.touches[0].clientX;
            },
            handleTouchEnd(event) {
                this.endX = event.changedTouches[0].clientX;
                if (this.endX - this.startX > 20) {
                    this.ThisWeekN--;// 向右滑动，切换到上一个
                    this.$refs.carousel.prev();
                }
                if (this.startX - this.endX > 20) {
                    this.ThisWeekN++;// 向左滑动，切换到下一个
                    this.$refs.carousel.next();
                }
            },
            //---------------以下代码启动后随即执行----------------------------
            GetCurrSiteUser() {
                let that = this;
                that.Show1 = -1;
                //---1.判定登录者角色,除 游客外共计4种角色---2.初次登录者,直接转向相应的初始化页面---------------
                if (this.$store.state.UserName === '游客') this.Show1 = 0; else {
                    let UserName = this.$store.state.UserName;
                    let TJ = {
                        $or: [{CAdminUser: UserName, DocBool: '课程管理员'}, {CoacheUser: UserName, DocBool: '教练'}, {StudentUser: UserName, DocBool: '学生'}]
                    };
                    myMongoDBPost("GetDocs/SiteUsers", TJ, function (Docs) {
                        if (Docs.length === 0) that.Show1 = 0; else {
                            that.CurrSiteUser = Docs[0];
                            that.CurrCAdminUser = Docs[0].CAdminUser;
                            console.log(that.CurrSiteUser);
                            if (Docs[0].KBStatus === '新建') {
                                if (Docs[0].DocBool === '课程管理员') that.Show1 = 1; else alert('课程管理员尚未搭建好课表框架！')
                            } else if (Docs[0].KBStatus === '已初始化') {
                                if (Docs[0].DocBool === '教练') that.Show1 = 2; else if (Docs[0].DocBool === '学生') that.Show1 = 3;
                            } else if (Docs[0].KBStatus === '启用') that.ScheduleInit(); else alert('当前课表尚未启用！');
                            document.getElementById('TitleId').innerText = Docs[0].SiteShortName + '>约课器';
                        } //0.普通用户和没有当前默认场地管理员、教练、学生登录，均直接转走
                    });
                }
            },
            ScheduleInit() {
                this.SwiperH = this.CurrSiteUser.ClassTWs.length * 36 + 46;
                this.SetCurrWeekN(1);//内含课表框架初始化
                console.log(this.ThisWeekN);

                this.ReloadSwiperDatas();//从后台调取已保存课表数据
                if (this.CurrSiteUser.DocBool === '课程管理员') {
                    this.GetAllStudents();
                    this.GetAllCoaches('排课预设检查');
                }
                let asd = this.CurrSiteUser;
                if (asd.ClassTWs.length < 3) return;
                if (asd.DocBool === '教练' || asd.DocBool === '学生') {
                    if (asd.ClassTWs[0] === undefined)
                        alert('请先选择退出课表，再重新选择进入');
                    else if (asd.ClassTWs[0].Days === undefined) alert('您的排课预设尚未完成！!')
                }
            },
            SetCurrWeekN(Bool) {
                console.log(this.SwiperDatas);
                this.ThisYear = (new Date()).getFullYear();
                this.ThisMonth = (new Date()).getMonth() + 1;
                this.ThisWeekN = 0;
                let CurrWeekN = 0;
                if (Bool === 1) this.GetSwiperDatas();
                console.log(this.SwiperDatas);
                for (let i = 0; i < this.SwiperDatas.length; i++)
                    if (this.SwiperDatas.Year === this.ThisYear && this.SwiperDatas.Month === this.ThisMonth) this.ThisWeekN = this.SwiperDatas.WeekN;

            },
            ReloadSwiperDatas() {
                let that = this;
                let TJ = {SiteId: this.CurrSiteUser.SiteId, CAdminUser: this.CurrSiteUser.CAdminUser};
                myMongoDBPost("GetDocs/Schedules", TJ, function (Docs) {
                    console.log(Docs);
                    for (let i = 0; i < Docs.length; i++)
                        for (let j = 0; j < that.SwiperDatas.length; j++)
                            if (Docs[i].WeekN === that.SwiperDatas[j].WeekN) {
                                that.SwiperDatas[j] = CloneObject(Docs[i]);
                                for (let Col = 0; Col < 7; Col++) {
                                    let Thisday = that.SwiperDatas[j].WeekDays[Col].Day;
                                    let DayY = that.SwiperDatas[j].Year, DayM = parseInt(Thisday.split(".")[0]),
                                        DayD = parseInt(Thisday.split(".")[1]);
                                    let DayStamp = (new Date(DayY + '-' + DayM + '-' + DayD)).getTime(), Now = (new Date()).getTime();
                                    if (DayStamp < Now) that.SwiperDatas[j].WeekDays[Col].Bool2 = '冻结';
                                }
                            }
                    for (let j = 0; j < that.SwiperDatas.length; j++) that.SwiperDatas[j].Bool = false;//临时增加1个更新标志
                    //that.SwiperDatas = that.SwiperDatas;
                    console.log(that.SwiperDatas)
                });
            },
            GetSwiperDatas() {//SwiperDatas数据每次从课表启用时间算起，生成108周
                let that = this;

                function GetCells(ClassTWsLength) {
                    let aCell = {Valid: false, Cn: 0, Sn: 0, index4: -1, CSs: [], NoSs: []};
                    //CSs内是{Coache:??,Students:[]};index4=当前登录教练用户在CSs内的排序
                    let Cells = [];
                    for (let i = 0; i < ClassTWsLength; i++) {
                        Cells.push([]);
                        for (let j = 0; j < 7; j++) Cells[i].push(aCell)//每周有7天
                    }
                    return Cells
                }

                if (JSON.stringify(this.CurrSiteUser) === '{}') return [];

                that.SwiperDatas = [];
                myMongoDBPost("GetDocs/SiteUsers", {CAdminUser: that.CurrCAdminUser, DocBool: '课程管理员'}, function (Docs) {//步骤1：获取KBBeginStamp
                    let Thisday = new Date(Docs[0].KBBeginStamp), ClassTWs = that.CurrSiteUser.ClassTWs;
                    console.log(Thisday, Docs[0].KBBeginStamp)
                    console.log(Thisday.getFullYear(), Thisday.getMonth(), Thisday.getDate(), Thisday.getDay())
                    let WDayn, DayM, DayD, WeekDayBeginN = Thisday.getDay();
                    if (WeekDayBeginN === 0) WDayn = 6; else WDayn = WeekDayBeginN - 1;//步骤2： 求出课表启动日是星期几
                    for (let i = 0; i < 108; i++) that.SwiperDatas.push({WeekN: 0, Year: 0, Month: 0, WeekDays: [], Cells: [],})
                    for (let i = 0; i < 108; i++) {
                        for (let j = 0; j < 7; j++)//步骤3： 填充WeekDays
                            if (i === 0 && j <= WDayn)//课表启动日之前填充为空
                                that.SwiperDatas[0].WeekDays.push({Day: '', Bool1: null, Bool2: null});
                            else {//课表启动日之后分别填充
                                let Bool2 = '可变', oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数;
                                Thisday = new Date(Docs[0].KBBeginStamp + (6 - WDayn + (i - 1) * 7 + j + 1) * oneDay);
                                DayM = Thisday.getMonth() + 1, DayD = Thisday.getDate();
                                let DayStamp = (new Date(Thisday.getFullYear() + '-' + DayM + '-' + DayD)).getTime(),
                                    Now = (new Date()).getTime();
                                if (DayStamp < Now) Bool2 = '冻结';
                                that.SwiperDatas[i].WeekDays.push({Day: '' + DayM + '.' + DayD, Bool1: (j > 4 ? '节假日' : '工作日'), Bool2: Bool2});
                            }
                        that.SwiperDatas[i].WeekN = i;//步骤4： 填充其它
                        that.SwiperDatas[i].Year = Thisday.getFullYear();
                        that.SwiperDatas[i].Month = Thisday.getMonth() + 1;
                        that.SwiperDatas[i].Cells = GetCells(ClassTWs.length);
                    }
                });
            },
            //====================以下代码点击时执行=========================
            OpenSetup() {
                this.show1 = 0;
                if (this.CurrSiteUser.DocBool === '课程管理员') this.Show1 = 1;//默认为课程管理员
                if (this.CurrSiteUser.DocBool === '教练') this.Show1 = 2;//wx.navigateTo({ url: '../Setup2/Setup2' });
                if (this.CurrSiteUser.DocBool === '学生') this.Show1 = 3;
            },
            ChangeMonth(N) {
                let NewMonth = this.ThisMonth + N, Month = 0, NewWeekN = 0;
                if (NewMonth === 0 || NewMonth === 13) {
                    this.ThisYear = this.ThisYear + N;
                    this.ThisMonth = 1;
                    this.ThisWeekN = 0;

                } else {
                    for (let i = 0; i < this.SwiperDatas.length; i++) {
                        NewWeekN = 0;
                        for (let j = 0; j < 7; j++) {
                            Month = parseInt(this.SwiperDatas[i].WeekDays[j].Day.split('.')[0]);
                            if (Month === NewMonth) {
                                NewWeekN = i;
                                break;
                            }
                        }
                        if (NewWeekN > 0) break;
                    }
                    this.ThisMonth = NewMonth;
                    this.ThisWeekN = NewWeekN;
                }
                this.$refs.carousel.setActiveItem(this.ThisWeekN)
            },
            SwiperChange(current, prev) {
                let Month = parseInt(this.SwiperDatas[current].WeekDays[6].Day.split('.')[0]);
                this.ThisYear = this.SwiperDatas[current].Year;
                this.ThisMonth = this.SwiperDatas[current].Month;
                this.ThisWeekN = current;
                if (this.ThisWeekN <= 0 || this.ThisWeekN >= 108) ElMessage('到头了！')
            },
            DateClick(index1, index2) {
                let asd = this.SwiperDatas[index1].WeekDays[index2];
                let DayY = this.SwiperDatas[index1].Year;
                let DayM = parseInt(asd.Day.split(".")[0]);
                let DayD = parseInt(asd.Day.split(".")[1]);
                let DayStamp = (new Date(DayY + '-' + DayM + '-' + DayD)).getTime(), Now = (new Date()).getTime();
                if (DayStamp <= Now) return ElMessage('点击无效！');

                if (this.CurrSiteUser.DocBool === '课程管理员') {
                    asd.Bool1 = (asd.Bool1 === "禁排日" ? "排课日" : "禁排日");
                    this.KBStart();
                }
                this.SaveScheduleData(this.SwiperDatas[index1]);
            },//今天以前的,禁止更改
            //====================从YKQSetup123返回后================================
            DoFromYKQSetup1(Val) {
                if (Val === '返回') this.GetCurrSiteUser();
                if (Val === 'KBStart') this.KBStart();
                if (Val === 'KBInit') {
                    alert('课表初始化完毕，请通知所有教练进行排课预设！');
                    this.GetCurrSiteUser();
                }
            },
            DoFromYKQSetup23(Val) {
                if (Val === '预设完毕') this.GetCurrSiteUser();
                if (Val === 'ToSetup0') this.Show1 = 0;
            },
            KBStart() {//注意：课程管理员调用有效
                let that = this;
                that.GetCurrSiteUser();
                myMongoDBPost("GetDocs/SiteUsers", {CAdminUser: that.$store.state.UserName}, function (Docs) {
                    let N = 0, ERR = '';
                    for (let i = 0; i < Docs.length; i++) {
                        if (Docs[i].KBStatus === '新建') N++;
                        if (Docs[i].DocBool === '教练' && Docs[i].KBStatus !== '预设完毕' && Docs[i].KBStatus !== '启用') {
                            N++;
                            ERR += Docs[i].CoacheUser + ":" + Docs[i].KBStatus + ";";
                        }
                        if (Docs[i].DocBool === '学生' && Docs[i].KBStatus !== '预设完毕' && Docs[i].KBStatus !== '启用') {
                            N++;
                            ERR += Docs[i].StudentUser + ":" + Docs[i].KBStatus + ";"
                        }
                    }
                    if (N === 0) myMongoDBPost("UpdateDoc/SiteUsers", {
                        UPTJ: {CAdminUser: that.$store.state.UserName},
                        UPObj: {KBStatus: '启用'}
                    }, function (Docs) {
                        that.RePK();
                    }); else alert('课表启用失败>' + ERR);
                });
            },
            //------------------------以下代码用来显示课表----------------------

            ShowChanged(Show, Role) {
                console.log(Show, typeof Show, Role);
                if (Show === 1) this.ReloadSwiperDatas(); else this.ResetSwiperDatas(Role);
            },
            ResetSwiperDatas(Role) {
                let that = this, Yws = CloneObject(this.SwiperDatas);
                for (let j = 0; j < Yws.length; j++)
                    for (let Row = 0; Row < Yws[j].Cells.length; Row++)
                        for (let Col = 0; Col < Yws[j].Cells[Row].length; Col++) {
                            let CellObj = CloneObject(Yws[j].Cells[Row][Col]), ASDs;
                            CellObj.Valid = false;
                            if (Role === '学生') {
                                ASDs = CellObj.CSs.map(function (x) {
                                    return x.Students.indexOf(that.$store.state.UserName) >= 0
                                })
                            }
                            if (Role === '教练') {
                                ASDs = CellObj.CSs.map(function (x) {
                                    return x.Coache === that.$store.state.UserName
                                })
                            }
                            if (ASDs.indexOf(true) >= 0) CellObj.Valid = true;
                            Yws[j].Cells[Row][Col] = CellObj;
                        }
                //this.SwiperDatas = this.SwiperDatas;
                this.SwiperDatas = CloneObject(Yws);
                console.log(this.SwiperDatas)
            },//用于显示课表类型时的重设
            //--------------以下跟排课有关------------------------------------------------------------------
            GetAllStudents() {
                function CompareYKType(Property) {
                    return function (a, b) {
                        let val1 = parseInt(a[Property].split("对")[1]);
                        let val2 = parseInt(b[Property].split("对")[1]);
                        if (val2 < val1) {
                            return 1;
                        } else if (val2 > val1) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                }

                let that = this;
                let TJ = {CAdminUser: this.$store.state.UserName, DocBool: '学生'};
                myMongoDBPost("GetDocs/SiteUsers", TJ, function (Docs) {
                    console.log(Docs);
                    if (Docs.length === 0) ElMessage('没有学生!!!'); else {
                        //先要重新排序:1对1的靠前,1对2 的次之,1对n的最后
                        let asd = [], ErrStr = '';
                        for (let i = 0; i < Docs.length; i++) {
                            if (Docs[i].HourN === 0 || Docs[i].HourN === null) ErrStr += '[' + Docs[i].StudentUser + ']学时未设定!';
                            else if (Docs[i].KBStatus !== '预设完毕' && Docs[i].KBStatus !== '启用') ErrStr += '[' + Docs[i].StudentUser + ']未完成预设!';
                            else if (Docs[i].ClassTWs[0] === undefined) ErrStr += '[' + Docs[i].StudentUser + ']未预设!';
                            else if (Docs[i].ClassTWs[0].Days === undefined) ErrStr += '[' + Docs[i].StudentUser + ']未预设!';
                            else if (Docs[i].YKType === "1对?") ErrStr += '[' + Docs[i].StudentUser + ']未设约课类型!';
                            else asd.push(Docs[i]);
                        }
                        if (ErrStr !== '') alert('未完成排课预设的学生：' + ErrStr);
                        asd.sort(CompareYKType('YKType'));
                        that.AllStudents = asd;
                    }
                });
            },
            RePK() {
                this.SetCurrWeekN(2);
                let BeginWeekN = this.ThisWeekN, ToDay = DateStr2(new Date());//步骤1.确定当前时间点
                console.log(BeginWeekN, ToDay, this.SwiperDatas[BeginWeekN])
                let aAs = this.SwiperDatas[BeginWeekN].WeekDays.map(function (x) {
                    return (x.Day === ToDay)
                });
                let ToDayN = aAs.indexOf(true);

                //步骤2.通过今天以前的数据，计算并填充每个学生的已用学时数HouredN
                for (let i = 0; i < this.AllStudents.length; i++) {//对于每一个学生
                    let CurrSt = CloneObject(this.AllStudents[i]);
                    CurrSt.HouredN = 0;
                    for (let j = 0; j <= BeginWeekN; j++)
                        for (let Col = 0; Col < (j === BeginWeekN ? ToDayN + 1 : 7); Col++)
                            for (let Row = 0; Row < this.SwiperDatas[j].Cells.length; Row++) {//如果在当前Cell中，找到了该学生，则应将其HouredN增加CurrHn
                                let CurrHn = this.CurrSiteUser.ClassTWs[Row].Hn;
                                let CellObj = CloneObject(this.SwiperDatas[j].Cells[Row][Col]);
                                if (CellObj.Valid) {
                                    let ASDs = CellObj.CSs.map(function (x) {
                                        return (x.Students.indexOf(CurrSt.StudentUser) > -1)
                                    });
                                    if (ASDs.indexOf(true) >= 0) CurrSt.HouredN += CurrHn;
                                }
                            }
                    this.AllStudents[i] = CloneObject(CurrSt);
                    console.log(CurrSt)
                }

                let BeginCol = 0;//步骤3.明天以后的数据全部清空，但禁排数据要保留
                if (ToDayN === 6) BeginWeekN++; else BeginCol = ToDayN + 1;//如果今天是本周的最后1列,则从下周算起
                //BeginCol = 1;//临时的
                for (let j = BeginWeekN; j < this.SwiperDatas.length; j++) {
                    for (let Col = (j > BeginWeekN ? 0 : BeginCol); Col < 7; Col++) {
                        for (let Row = 0; Row < this.SwiperDatas[j].Cells.length; Row++) {
                            let aCell = {Valid: false, Cn: 0, Sn: 0, index4: -1, CSs: [], NoSs: this.SwiperDatas[j].Cells[Row][Col].NoSs};
                            this.SwiperDatas[j].Cells[Row].splice(Col, 1, aCell);
                        }
                    }
                }
                //this.SwiperDatas = this.SwiperDatas;

                //步骤4.重排
                //console.log(ToDayN, BeginWeekN, BeginCol)
                let TableN = this.CurrSiteUser.YKMaxN;//场地容量=最大可用台子数=同时容纳教练个数
                let ClassTWs = this.CurrSiteUser.ClassTWs;//系统的排课时间窗

                for (let i = 0; i < this.AllStudents.length; i++) {//对于每一个学生,取出其ClassTWs比对着填充Cell
                    let CurrSt = this.AllStudents[i], CurrC = this.AllStudents[i].CoacheUser;
                    let PKHourN = CurrSt.HourN - CurrSt.HouredN;//剩余的可排课学时
                    //let MyYKType = this.AllStudents[i].YKType;
                    let MyClassTWs = this.AllStudents[i].ClassTWs;
                    for (let j = BeginWeekN; j < this.SwiperDatas.length; j++) {
                        //按列遍历Cells,对于每一个Cell先检查该教练是否可用,如果是1对1的而且是已经有了1个学生,就不能用,然后看下1个时段,每天1次
                        for (let Col = (j > BeginWeekN ? 0 : BeginCol); Col < 7; Col++) {
                            let TodayPKed = false;
                            for (let Row = 0; Row < this.SwiperDatas[j].Cells.length; Row++) {
                                let CellObj = CloneObject(this.SwiperDatas[j].Cells[Row][Col]), SN = 0;
                                //当前Cell是否可以排课,发生以下情况之一,都不能排
                                //console.log(this.SwiperDatas[j].WeekDays[Col])
                                if (this.SwiperDatas[j].WeekDays[Col] && this.SwiperDatas[j].WeekDays[Col].Bool1 === '禁排日') break;
                                if (MyClassTWs[Row] === undefined || !MyClassTWs[Row].Days[Col]) continue;//1.预排允许
                                if (TodayPKed) break;//2.如果当天排过
                                if (CellObj.CSs.length > TableN) continue;//3.当前时间窗课已排满
                                if (PKHourN <= 0) continue;//4.如果剩余的可排课学时已用完
                                if (ClassTWs[Row].Hn - PKHourN >= 1) continue;//5.如果剩余的少量学时试图占用一个较大的排课时间窗
                                if (CellObj.NoSs && CellObj.NoSs.indexOf(CurrSt.StudentUser) >= 0) {
                                    CellObj.Valid = true;
                                    continue;
                                }//6.这里有个禁排
                                if (CellObj.NoSs === '系统禁排') break;//6.这里有个禁排
                                let asd, ASDs = CellObj.CSs.map(function (x) {
                                    return (x.Coache === CurrC)
                                });//7.当前Cell教练可用

                                if (ASDs.indexOf(true) === -1) {//情况1:我的教练尚无数据
                                    asd = {Coache: CurrC, Students: [CurrSt.StudentUser]};
                                    CellObj.CSs.push(asd);
                                    CellObj.Valid = true;
                                    //if (Row === 1 && Col === 1) console.log(asd)
                                } else {//情况2:我的教练已有数据,先看上1个是1对多少。根据YKType排课，1对1最前，1对2 次之...;
                                    asd = CellObj.CSs[ASDs.indexOf(true)];
                                    let CurrSn = asd.Students.length;
                                    let Last1ToN = parseInt(this.GetYKType(asd.Students[asd.Students.length - 1]).split("对")[1]);
                                    if (CurrSn < Last1ToN) {
                                        asd.Students.push(CurrSt.StudentUser);
                                        CellObj.Valid = true;
                                        CellObj.CSs.splice(ASDs.indexOf(true), 1, asd);
                                    } else continue;
                                    //if (Row === 1 && Col === 1) console.log(j, CurrSt.StudentUser, CellObj.CSs, ASDs.indexOf(true))
                                }

                                //if (Row === 1 && Col === 1) console.log(j, CurrSt.StudentUser, CellObj, ASDs.indexOf(true))
                                CellObj.Cn = CellObj.CSs.length;
                                CellObj.CSs.forEach(element => {
                                    SN += element.Students.length;
                                });
                                CellObj.Sn = SN;
                                CellObj.index4 = -1;
                                this.SwiperDatas[j].Cells[Row].splice(Col, 1, CellObj);
                                this.SwiperDatas[j].Bool = true;//只有被修改过的Cell才会被存盘
                                PKHourN -= this.CurrSiteUser.ClassTWs[Row].Hn;
                                TodayPKed = true;//一天只能排1次

                            }
                        }
                        let Thisday = this.SwiperDatas[j].WeekDays[6].Day;
                        let DayY = this.SwiperDatas[j].Year, DayM = parseInt(Thisday.split(".")[0]), DayD = parseInt(Thisday.split(".")[1]);
                        let DayStamp = (new Date(DayY + '-' + DayM + '-' + DayD)).getTime(), EndStamp = this.AllStudents[i].EndStamp;
                        if (PKHourN > 0 && DayStamp > EndStamp) {
                            console.log(CurrSt.StudentUser, j, PKHourN, DayStamp - EndStamp, DayStamp + "=" + DayY + '-' + DayM + '-' + DayD, EndStamp + "=" + StampToDate(EndStamp))
                            alert("重要提示：学生" + this.AllStudents[i].StudentUser + "现有" + PKHourN +
                                "学时排在了预定的结课时间之后！要想在" + StampToDate(EndStamp) + "之前排完，需要更改排课预设。")
                        }
                    }
                }
                console.log(this.SwiperDatas);
                for (let i = BeginWeekN; i < this.SwiperDatas.length; i++)
                    if (this.SwiperDatas[i].Bool) this.SaveScheduleData(this.SwiperDatas[i]);
            },//每次重排都是 把明天以后的课表数据，先清空再重排
            //--------------以下跟课时统计有关------------------------------------------------------------------
            //HoursCount() {                this.GetAllCoaches('课时统计');            },
            GetYKType(Student) {
                for (let n = 0; n < this.AllStudents.length; n++)
                    if (this.AllStudents[n].StudentUser === Student) return this.AllStudents[n].YKType;
            },
            GetCoacheIndex(aCoache) {
                for (let n = 0; n < this.AllCoaches.length; n++) if (this.AllCoaches[n].Coache === aCoache) return n;
            },
            HoursCount() {
                for (let i = 0; i < this.AllStudents.length; i++) this.AllStudents[i].HouredN = 0;//已用学时数初始化
                this.SetCurrWeekN(2);
                let CurrWeekN = this.ThisWeekN, ToDay = DateStr2(new Date());
                let aAs = this.SwiperDatas[CurrWeekN].WeekDays.map(function (x) {
                    return (x.Day === ToDay)
                });
                let ToDayN = aAs.indexOf(true) + 1;
                //console.log(CurrWeekN,ToDayN)
                for (let j = 0; j <= CurrWeekN; j++) {
                    for (let Col = 0; Col < (j === CurrWeekN ? ToDayN : 7); Col++) {
                        for (let Row = 0; Row < this.SwiperDatas[j].Cells.length; Row++) {
                            let CurrCell = this.SwiperDatas[j].Cells[Row][Col];
                            let CurrHn = this.CurrSiteUser.ClassTWs[Row].Hn;
                            //if (j === CurrWeekN) console.log(CurrWeekN, Row, Col)
                            if (CurrCell.Valid) {
                                //console.log(CurrHn, "=====走到这里======>")
                                for (let i = 0; i < CurrCell.CSs.length; i++) {//以下从CSs[i].Students中取出数据,计算后填充this.AllCoaches
                                    let aIndex = this.GetCoacheIndex(CurrCell.CSs[i].Coache);
                                    //console.log(i, CurrCell.CSs[i], "---------------->")
                                    for (let k = 0; k < CurrCell.CSs[i].Students.length; k++) {
                                        let CurrStu = CurrCell.CSs[i].Students[k];//console.log(aIndex, CurrStu, this.GetYKType(CurrStu))
                                        if (this.AllCoaches[aIndex] !== undefined && this.GetYKType(CurrStu) === '1对1') {
                                            this.AllCoaches[aIndex].Stu11N += 1;
                                            this.AllCoaches[aIndex].Stu11H += CurrHn;
                                        }
                                        if (this.AllCoaches[aIndex] !== undefined && this.GetYKType(CurrStu) === '1对2') {
                                            this.AllCoaches[aIndex].Stu12N += 1;
                                            this.AllCoaches[aIndex].Stu12H += CurrHn;
                                        }
                                        if (this.AllCoaches[aIndex] !== undefined && this.GetYKType(CurrStu) === '1对n') {
                                            this.AllCoaches[aIndex].Stu1nN += 1;
                                            this.AllCoaches[aIndex].Stu1nH += CurrHn;
                                        }
                                        for (let m = 0; m < this.AllStudents.length; m++)
                                            if (CurrStu === this.AllStudents[m].StudentUser) this.AllStudents[m].HouredN += CurrHn;//填充已用学时数
                                    }
                                }
                            }
                        }
                    }
                }
                for (let i = 0; i < this.AllCoaches.length; i++) {
                    let asd = this.AllCoaches[i];
                    asd.SumHn = asd.Stu11H + asd.Stu12H + asd.Stu1nH;
                    this.AllCoaches[i] = asd;
                }
                //this.AllCoaches = this.AllCoaches;
                this.Show = 3;
                this.SaveAllStudents(this.AllStudents);
            },
            SaveAllStudents(AllStudents) {
                this.AllStudents = AllStudents;
                myMongoDBPost("UpdateObjs/SiteUsers", AllStudents, function (asd) {
                })
            },
            //--------------以下跟调课有关------------------------------------------------------------------

            CellClick(index1, index2, index3) {
                let CurrCell = this.SwiperDatas[index1].Cells[index2][index3];
                this.aBool = CurrCell.NoSs !== '系统禁排' && CurrCell.NoSs.indexOf(this.$store.state.UserName) < 0;
                console.log(index1, index2, index3, CurrCell);
                this.ShowLandscape = true;
                this.CurrCell = CurrCell;
                this.CurrCellIndex = {index1: index1, index2: index2, index3: index3}
            },
            SetMyNo() {
                console.log(this.CurrSiteUser.DocBool, this.aBool);
                let index1 = this.CurrCellIndex.index1, index2 = this.CurrCellIndex.index2, index3 = this.CurrCellIndex.index3;
                let CurrCell = this.SwiperDatas[index1].Cells[index2][index3];
                if (this.SwiperDatas[index1].WeekDays[index3].Bool2 === '可变') {
                    if (this.CurrSiteUser.DocBool === '课程管理员') {
                        if (this.aBool) CurrCell = {Valid: true, Cn: 0, Sn: 0, index4: -1, CSs: [], NoSs: []};
                        else CurrCell = {Valid: true, Cn: 0, Sn: 0, index4: -1, CSs: [], NoSs: '系统禁排'};//设为禁排
                    }
                    if (this.CurrSiteUser.DocBool === '学生') {
                        let CurrSt = this.CurrSiteUser.StudentUser;
                        if (this.aBool) {
                            if (CurrCell.NoSs.indexOf(CurrSt) >= 0) CurrCell.NoSs.splice(CurrCell.NoSs.indexOf(CurrSt), 1);//取消禁排
                        }
                        else {//设为禁排，从CSs中找到，并删除他
                            CurrCell.CSs.forEach(function (x) {
                                let i = x.Students.indexOf(CurrSt);
                                if (i >= 0) {
                                    x.Students.splice(i, 1);
                                    CurrCell.Sn -= 1;
                                }
                            });
                            let ASDs = CurrCell.CSs.map(function (x) {
                                return (x.Students.length === 0)
                            });
                            if (ASDs.indexOf(true) >= 0) {
                                CurrCell.CSs.splice(ASDs.indexOf(true), 1);
                                CurrCell.Cn -= 1;
                            }
                            if (CurrCell.NoSs === undefined) CurrCell.NoSs = [CurrSt];
                            if (CurrCell.NoSs.indexOf(CurrSt) === -1) CurrCell.NoSs.push(CurrSt);//将其添加到NoSs中
                            if (CurrCell.CSs.length === 0) CurrCell = {Valid: true, Cn: 0, Sn: 0, index4: -1, CSs: [], NoSs: [CurrSt]};//禁排
                        }
                    }
                }
                this.SwiperDatas[index1].Cells[index2].splice(index3, 1, CurrCell);
                //this.SwiperDatas = this.SwiperDatas;
                this.CurrCell = CurrCell;
                this.SaveScheduleData(this.SwiperDatas[index1]);
                console.log(this.SwiperDatas)
                alert('您如果做了更新，请通知课程管理员进行一下课表重排 ！');
                this.ShowLandscape = false;
            },
            SaveScheduleData(aObj) {
                if (aObj._id === undefined) {
                    aObj.SiteId = this.CurrSiteUser.SiteId;
                    aObj.CAdminUser = this.CurrSiteUser.CAdminUser;
                    myMongoDBPost("PutDoc/Schedules", aObj, function (res) {
                    })
                } else {
                    myMongoDBPost('UpdateDoc/Schedules', {UPTJ: {_id: aObj._id}, UPObj: {Cells: aObj.Cells, WeekDays: aObj.WeekDays}},
                        function (res) {
                        })
                }
            },
            //----------------------以下是被二次调用的代码---------------------------------------------
            GetAllCoaches(Bool) {
                let that = this;
                let TJ = {CAdminUser: this.$store.state.UserName, DocBool: '教练'};
                myMongoDBPost('GetDocs/SiteUsers', TJ, function (Docs) {
                    let Coaches = [];//CoacheNames = [],
                    if (Bool === '课时统计') {
                        for (let i = 0; i < Docs.length; i++) //CoacheNames.push(Docs[i].CoacheUser);
                            Coaches.push({
                                Coache: Docs[i].CoacheUser, Stu11N: 0, Stu11H: 0, Stu12N: 0, Stu12H: 0, Stu1nN: 0, Stu1nH: 0,
                                SumHn: 0
                            });
                        that.AllCoaches = Coaches;// AllCoacheNames: CoacheNames,
                        that.HoursCount();
                    }
                    if (Bool === '排课预设检查' && Docs.length > 0) {
                        for (let i = 0; i < Docs.length; i++)
                            if (Docs[i].KBStatus !== '预设完毕' && Docs[i].KBStatus !== '启用') Coaches.push(Docs[i].CoacheUser);
                        if (Coaches.length > 0) alert(['上尚未完成排课预设的教练: '].concat(Coaches).toString());
                    }
                })
            }
        }
    }

</script>

<style scoped>
    .CellBG1 {
        background: 0 0/100% 100% url('http://www.fy135.vip/UserUpload/static/Img/CellBG1.png') no-repeat;
        }
    
    .CellBG2 {
        background: 0 0/100% 100% url('http://www.fy135.vip/UserUpload/static/Img/CellBG2.png') no-repeat;
        }
</style>
